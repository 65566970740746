const theme = {
  colors: {
    primary: '#212843',
    primaryHalfOpacity: '#21284380',
    primaryVariation: '#1f2641',
    primaryDark: '#181d36',
    primaryDarker: '#111830',
    primaryLight: '#252c48',
    primaryLighter: '#47537a',

    black: '#000000',

    gray1: 'rgba(24, 29, 54, 0.9)',
    gray2: 'rgba(24, 29, 54, 0.8)',
    gray3: 'rgba(24, 29, 54, 0.7)',
    gray4: 'rgba(24, 29, 54, 0.6)',
    gray5: 'rgba(24, 29, 54, 0.5)',
    gray6: 'rgba(24, 29, 54, 0.4)',
    gray7: 'rgba(24, 29, 54, 0.3)',
    gray8: 'rgba(24, 29, 54, 0.2)',
    gray9: 'rgba(24, 29, 54, 0.1)',

    whiteGray1: 'rgba(255, 255, 255, 0.9)',
    whiteGray2: 'rgba(255, 255, 255, 0.8)',
    whiteGray3: 'rgba(255, 255, 255, 0.7)',
    whiteGray4: 'rgba(255, 255, 255, 0.6)',
    whiteGray5: 'rgba(255, 255, 255, 0.5)',
    whiteGray6: 'rgba(255, 255, 255, 0.4)',
    whiteGray7: 'rgba(255, 255, 255, 0.3)',
    whiteGray8: 'rgba(255, 255, 255, 0.2)',
    whiteGray9: 'rgba(255, 255, 255, 0.1)',

    blue: '#2c97de',
    green: '#00ce7f',
    lightGreen: '#00d4a6',
    orange: '#fdab3d',
    violet: '#9300e9',
    red: '#ed0033',
    pink: '#ff3171',
    lightBlue: '#58bfe7',
    blue2: '#0076ff',

    textGray: 'rgba(255, 255, 255, 0.5)',
    white: 'white',
  },
  shadow: {
    default: '0 2px 6px 0 rgba(0,0,0,0.15);',
  },
  layout: {
    headerHeight: '80px',
    sidebarWidth: '250px',
    sidebarClosedWidth: '80px',
    collapsedSidebarWidth: '40px',
    default: `
      padding-right: 2rem;
      padding-left: 2rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;

      @media screen and (max-width: 768px){
        padding: 1rem;
      }
    `,
    small: `@media screen and (max-width: 768px)`,
    mobile: `@media screen and (max-width: 768px)`,
    large: `@media screen and (min-width: 769px)`,
    desktop: `@media screen and (min-width: 769px)`,
  },
  standardBorderRadius: '4px',
  screenLarge: '@media screen and (max-width: 1600px)',
  screenMedium: '@media screen and (max-width: 768px)',
  screenSmall: '@media screen and (max-width: 400px)',
  defaultContentWrapper: `
    max-width: 1100px;
    width: 80%;
    margin: 0 auto;
    margin-top: 3rem;
    position: relative;
  `,
  resetButton: `
    appearance: none;
    border: none;
    background: none;
  `,
}

export default theme
