// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react'
import { classNames } from '@plasmicapp/react-web'

export type ActionsMoresvgIconProps = React.ComponentProps<'svg'> & {
  title?: string
}

export function ActionsMoresvgIcon(props: ActionsMoresvgIconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      version={'1.1'}
      x={'0'}
      y={'0'}
      viewBox={'0 0 46 9.857'}
      xmlSpace={'preserve'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={'currentColor'}>
        <circle cx={'23'} cy={'4.929'} r={'4.929'}></circle>

        <circle cx={'41.071'} cy={'4.929'} r={'4.929'}></circle>

        <circle cx={'4.929'} cy={'4.929'} r={'4.929'}></circle>
      </g>
    </svg>
  )
}

export default ActionsMoresvgIcon
/* prettier-ignore-end */
