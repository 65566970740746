export const API_URL = isLocal()
  ? 'http://localhost:8020'
  : isDev()
    ? 'https://api.widar.dev.lpg.ninja'
    : isStaging()
      ? 'https://api.widar.staging.lpg.ninja'
      : isProd()
        ? 'https://api.smartcylinders.com'
        : ''

function isEnv(env: string) {
  return process.env.REACT_APP_ENVIRONMENT === env
}

export function isLocal() {
  return isEnv('local')
}

export function isStaging() {
  return isEnv('staging')
}

export function isDev() {
  return isEnv('dev')
}

export function isProd() {
  return isEnv('prod')
}

export function getCustomEnvironment() {
  return process.env.REACT_APP_ENVIRONMENT || 'unknown'
}

export const getDistributorFromDomain = () => {
  const dist = window.location.hostname.split('.')[0]
  return dist === 'localhost' || dist === 'drivers' ? null : dist
}

export const getBareDomain = () => {
  const domain = window.location.hostname.split('.')
  return domain[0] === 'localhost' || domain[0] === 'drivers' ? domain.join('.') : domain.slice(1).join('.')
}

export const getRootDomain = () => {
  const domain = window.location.hostname.split('.')
  return domain.slice(Math.max(domain.length - 2, 0), domain.length).join('.')
}

export const getDomain = () => {
  const domain = window.location.hostname.split('.')
  return domain.slice(domain.indexOf('drivers') + 1).join('.')
}

export const getUrl = () => {
  return isLocal() ? 'localhost:3000' : isDev() ? 'dev.lpg.ninja' : isStaging() ? 'staging.lpg.ninja' : isProd() ? 'smartcylinders.com' : '' // Unknown environment
}
