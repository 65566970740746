import React from 'react'
import './index.css'
import * as serviceWorker from './serviceWorker'
import Root from 'containers/Root'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { getCustomEnvironment, isLocal } from 'util/env'
import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'

console.log(process.env)

if (!isLocal()) {
  Sentry.init({
    dsn: 'https://17d60e8c30304c0589dc17ff0e546d50@o241718.ingest.sentry.io/5913927',
    environment: getCustomEnvironment(),
    release: 'driver-app@' + (process.env.REACT_APP_CI_COMMIT_REF_NAME || 'local') + '-' + (process.env.REACT_APP_CI_JOB_ID || '0'),
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
        stickySession: true,
      }),
    ],
  })
}

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(<Root />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
