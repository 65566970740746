import React from 'react'
import { removeLoginToken } from './util'
import client from 'apollo'

interface LogoutProps {}

const Logout: React.FC<LogoutProps> = () => {
  removeLoginToken()
  client.resetStore()
  window.location.reload()
  console.log('Logout')

  return <>Logout</>
}

export default Logout
