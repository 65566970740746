import React from 'react'
import { useIntl } from 'react-intl'
import FlexCenter from 'components/FlexCenter'
import Icon from 'components/Icon'
import Margin from 'components/Margin'

interface CenteredErrorMessageProps {
  message?: string
  className?: string
  intlId?: string
  defaultMessage?: string
  icon?: string
  marginTop?: string
  noAccess?: boolean
  children?: React.ReactNode
}

const CenteredErrorMessage: React.FC<CenteredErrorMessageProps> = ({
  className,
  intlId,
  defaultMessage,
  message,
  icon = 'exclamation-triangle',
  marginTop = '6em',
  noAccess = false,
  children,
}) => {
  const intl = useIntl()
  const _message = noAccess
    ? intl.formatMessage({
        id: 'permissions.no-access-to-page',
        defaultMessage: 'No access',
      })
    : intlId
      ? intl.formatMessage({
          id: intlId,
          defaultMessage: defaultMessage,
        })
      : message || 'Server error. Please try refreshing the page.'
  const _icon = typeof icon === 'string' ? <Icon icon={icon} color='error' /> : icon
  return (
    <FlexCenter fillParent vertical horizontal className={className} direction='column'>
      <div>
        <Margin inline right='6px'>
          {_icon}
        </Margin>
        <div>{_message}</div>
      </div>
      {children}
    </FlexCenter>
  )
}

export default CenteredErrorMessage
