import { addDays, startOfYear, addMonths } from 'date-fns'
import { format, startOfWeek } from 'util/date-fns'

export function getDayNames(frmt: 'M' | 'Mo' | 'Mon' | 'Monday' = 'Monday') {
  const nameFormat = {
    M: 'EEEEE',
    Mo: 'EEEEEE',
    Mon: 'EEE',
    Monday: 'EEEE',
  }[frmt]

  const names: string[] = []
  let curDate = startOfWeek(new Date())
  for (let _ = 0; _ < 7; _++) {
    names.push(format(curDate, nameFormat))
    curDate = addDays(curDate, 1)
  }
  return names
}

export function getMonthNames(frmt: 'J' | 'Jan' | 'January' = 'January') {
  const nameFormat = {
    J: 'LLLLL',
    Jan: 'LLL',
    January: 'LLLL',
  }[frmt]

  const names: string[] = []
  let curDate = startOfYear(new Date())
  for (let _ = 0; _ < 12; _++) {
    names.push(format(curDate, nameFormat))
    curDate = addMonths(curDate, 1)
  }
  return names
}

export const getPositionFromDate = (date: Date): number => {
  return parseFloat(format(date, 'HH') + ((parseInt(format(date, 'mm')) / 60) * 100).toString().padStart(2, '0'))
}
