import React from 'react'
import { DefaultTheme, useTheme } from 'styled-components'
import ReactModal from 'react-modal'
import { useMedia } from 'util/hooks'

function getModalStyle(mobile: boolean, theme: DefaultTheme): object {
  const common = {
    overlay: {
      zIndex: 8000,
      background: 'rgba(0, 0, 0, 0.45)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    content: {
      margin: '0 auto',
      left: 'unset',
      right: 'unset',
      top: 'unset',
      bottom: 'unset',
      padding: '0',
      border: 'none',
      borderRadius: '8px',
      overflow: 'visible',
    },
  }
  if (mobile) {
    return {
      ...common,
    }
  } else {
    return {
      ...common,
    }
  }
}

interface IModalProps {
  isOpen: boolean
  onRequestClose: () => void
  children: React.ReactNode
}

const Modal: React.FC<IModalProps> = ({ isOpen, children, onRequestClose }) => {
  const [mobile] = useMedia()
  const theme = useTheme()
  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose} style={getModalStyle(mobile, theme)} ariaHideApp={false}>
      {children}
    </ReactModal>
  )
}

export default Modal
