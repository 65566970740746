import * as React from 'react'
import { PlasmicRouteSummary, DefaultRouteSummaryProps } from './plasmic/driver_app/PlasmicRouteSummary'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import { formatDuration } from 'modules/routing/RouteDetail/RouteTimeline.util'
import { intervalToDuration } from 'date-fns'
import { parseISO } from 'date-fns'
import * as GQL from 'generated/graphql'
import { useCurrentTime } from 'util/hooks'

interface RouteSummaryProps extends DefaultRouteSummaryProps {
  route?: GQL.RouteNode
}

function RouteSummary_(props: RouteSummaryProps, ref: HTMLElementRefOf<'div'>) {
  const { route, ...plasmicProps } = props
  const now = useCurrentTime()

  return (
    <PlasmicRouteSummary
      root={{ ref }}
      durationTime={
        route && route.driverStartedRouteAt
          ? formatDuration(
              intervalToDuration({
                start: parseISO(route.driverStartedRouteAt!),
                end: now,
              })
            )
          : ''
      }
      {...plasmicProps}
    />
  )
}

const RouteSummary = React.forwardRef(RouteSummary_)
export default RouteSummary
