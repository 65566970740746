import * as React from 'react'
import { PlasmicComment, DefaultCommentProps } from './plasmic/driver_app/PlasmicComment'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

interface CommentProps extends DefaultCommentProps {
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void
  onChange?: (e?: React.ChangeEvent<HTMLTextAreaElement>) => void
  value?: string
  disabled?: boolean
}

function Comment_(props: CommentProps, ref: HTMLElementRefOf<'div'>) {
  const { onClick, onChange, value, disabled, ...plasmicProps } = props

  return (
    <PlasmicComment
      root={{ ref }}
      openCommentBtn={{
        props: {
          onClick: onClick,
        },
      }}
      textarea={{
        onChange: onChange,
        value: value ?? '',
        disabled: disabled ?? false,
      }}
      {...plasmicProps}
    />
  )
}

const Comment = React.forwardRef(Comment_)
export default Comment
