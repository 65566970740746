import { getDay } from 'date-fns'
import { Time, time } from 'lib/time'
import * as GQL from 'generated/graphql'

const dayLookup = {
  MONDAY: 0,
  TUESDAY: 1,
  WEDNESDAY: 2,
  THURSDAY: 3,
  FRIDAY: 4,
  SATURDAY: 5,
  SUNDAY: 6,
}

export function getNextStopIdFromRoute(route?: GQL.RouteNode | null) {
  if (!route) return null

  // We assume the stops are sorted here
  return route?.stops?.reduce<null | string>((acc, next) => {
    if (acc !== null) return acc
    if (next?.status === 'WAITING' || next?.status === 'STARTED') {
      return next.id
    }
    return acc
  }, null)
}

function getTodaysStartAndEndTimeForCustomer(customer: Pick<GQL.CustomerNode, 'standardOpeningHours'>) {
  const today = getDay(new Date())

  return customer?.standardOpeningHours?.reduce<[Time, Time] | null>((acc, next) => {
    if (acc) return acc

    if (next && dayLookup[next.weekday] === today) {
      return [time(next.opensAt), time(next.closesAt)]
    }

    return null
  }, null)
}

export function getCustomerIsOpenNow(customer: Pick<GQL.CustomerNode, 'standardOpeningHours'>): 'open' | 'closed' | 'soon' | undefined {
  const startEndTime = getTodaysStartAndEndTimeForCustomer(customer)
  if (!startEndTime) {
    return
  }

  const [start, end] = startEndTime

  const now = time(new Date())

  if (now.isAfter(end)) {
    return 'closed'
  }

  if (now.isAfter(start)) {
    return 'open'
  }

  if (now.isAfter(start.sub(1, 'hour'))) {
    return 'soon'
  }

  return
}

export function getCustomerOpeningWindowToday(customer: Pick<GQL.CustomerNode, 'standardOpeningHours'>) {
  const startEndTime = getTodaysStartAndEndTimeForCustomer(customer)
  if (!startEndTime) {
    return '-'
  }

  const [start, end] = startEndTime

  return `${start.format('HH:mm')} - ${end.format('HH:mm')}`
}

export function getRouteVisitedStops(route: GQL.RouteNode) {
  return (
    route?.stops?.reduce<number>((acc, next) => {
      if (next && next.status === 'COMPLETED') {
        return acc + 1
      }
      return acc
    }, 0) || 0
  )
}

export function getRouteDeliveredBottles(route: GQL.RouteNode) {
  return (
    route?.stops?.reduce<number>((acc, next) => {
      if (next && next.status === 'COMPLETED') {
        return acc + (next.order?.numberOfCylinders ?? 0)
      }
      return acc
    }, 0) || 0
  )
}

export function getRouteTotalBottles(route: GQL.RouteNode) {
  return (
    route?.stops?.reduce<number>((acc, next) => {
      return acc + (next?.order?.numberOfCylinders ?? 0)
    }, 0) || 0
  )
}
