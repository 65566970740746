import * as React from 'react'
import { PlasmicSelect, DefaultSelectProps } from './plasmic/driver_app/PlasmicSelect'
import { SelectRef } from '@plasmicapp/react-web'
import Option from './Select__Option'
import OptionGroup from './Select__OptionGroup'

interface SelectProps extends DefaultSelectProps {
  onChange?: (value: string | null) => void
}

function Select_(props: SelectProps, ref: SelectRef) {
  // state is available as a prop for destructing
  const { plasmicProps } = PlasmicSelect.useBehavior(props, ref)

  return <PlasmicSelect {...plasmicProps} />
}

const Select = React.forwardRef(Select_)

export default Object.assign(Select, {
  Option,
  OptionGroup,
  __plumeType: 'select',
})
