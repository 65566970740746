import * as React from 'react'
import { PlasmicOpenIndicator, DefaultOpenIndicatorProps } from './plasmic/driver_app/PlasmicOpenIndicator'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

interface OpenIndicatorProps extends DefaultOpenIndicatorProps {
  status?: 'open' | 'soon' | 'closed'
}

function OpenIndicator_(props: OpenIndicatorProps, ref: HTMLElementRefOf<'div'>) {
  return <PlasmicOpenIndicator root={{ ref }} {...props} />
}

const OpenIndicator = React.forwardRef(OpenIndicator_)
export default OpenIndicator
