// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react'
import { classNames } from '@plasmicapp/react-web'

export type MapPinsvgIconProps = React.ComponentProps<'svg'> & {
  title?: string
}

export function MapPinsvgIcon(props: MapPinsvgIconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      version={'1.1'}
      x={'0'}
      y={'0'}
      viewBox={'0 0 116.298 140.384'}
      xmlSpace={'preserve'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M116.298 58.149C116.298 26.034 90.264 0 58.149 0S0 26.034 0 58.149C0 73.5 5.993 87.42 15.71 97.813h-.132l42.571 42.571 42.572-42.571h-.133c9.718-10.393 15.71-24.313 15.71-39.664zm-58.149 34.89c-19.269 0-34.89-15.621-34.89-34.89s15.621-34.89 34.89-34.89 34.89 15.621 34.89 34.89-15.621 34.89-34.89 34.89z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  )
}

export default MapPinsvgIcon
/* prettier-ignore-end */
