import React, { useState } from 'react'
import { useUser } from 'util/hooks'
import * as GQL from 'generated/graphql'
import { format } from 'util/date-fns'
import Overview from 'components/Overview'
import ListRowRoute from 'components/ListRowRoute'
import { getDate, isToday, parseISO } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import EmptyStateRoutes from '../../components/EmptyStateRoutes'
import CacheConfigs from 'util/cacheConfig'

interface RoutesOverviewProps {}

const RoutesOverview: React.FC<RoutesOverviewProps> = () => {
  const [completedActive, setCompletedActive] = useState(false)

  const user = useUser()
  const now = new Date()
  const navigate = useNavigate()

  const { data } = GQL.useRoutes({
    ...CacheConfigs.ACCURATE_FREQUENT,
    variables: {
      driver: user?.driver?.id || '',
      date_Gte: format(now, 'yyyy-MM-dd'),
    },
  })

  const completedRoutes = data?.completedRoutes?.edges.map(edge => edge?.node as GQL.RouteNode) || []
  const upcomingRoutes = data?.upcomingRoutes?.edges.map(edge => edge?.node as GQL.RouteNode) || []

  const displayRoutes = completedActive ? completedRoutes : upcomingRoutes

  return (
    <Overview
      routesList={{
        props: {
          routes: (
            <>
              {displayRoutes.length > 0 ? (
                displayRoutes.map(routeInstance => {
                  const date = parseISO(routeInstance.date)

                  return (
                    <ListRowRoute
                      data-testid='route'
                      today={isToday(date)}
                      title={routeInstance.vehicle?.name + ' (' + routeInstance.vehicle?.registrationNumber + ')'}
                      key={routeInstance.id}
                      date={getDate(date)}
                      onClick={() => navigate(`/routes/${routeInstance.id}`)}
                      month={format(date, 'MMM')}
                      deliveries={routeInstance?.stops?.filter(stop => stop?.stopType !== 'PICKUP').length}
                      depotReloads={routeInstance?.stops?.filter(stop => stop?.stopType === 'PICKUP').length}
                    />
                  )
                })
              ) : (
                <EmptyStateRoutes />
              )}
            </>
          ),
          completed: completedActive,
          btnUpcoming: {
            props: {
              name: 'upcoming',
              onClick: () => setCompletedActive(false),
            },
          },
          btnCompleted: {
            props: {
              name: 'completed',
              onClick: () => setCompletedActive(true),
            },
          },
        },
      }}
    />
  )
}

export default RoutesOverview
