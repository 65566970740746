import nb from './translations/nb.json'
import en from './translations/en.json'

interface Translations {
  [key: string]: string
}

interface Languages {
  [key: string]: Translations
}

const messages: Languages = {
  nb,
  en,
  'nb-NO': en,
  'nn-NO': en,
  'en-GB': en,
  'en-US': en,
}

export default messages
