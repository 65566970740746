const saveLanguageKey = 'solace-language-preference'

export function savePreferredLanguage(language: string) {
  localStorage.setItem(saveLanguageKey, language)
}

export function getPreferredLanguage() {
  const saved = localStorage.getItem(saveLanguageKey)

  if (!saved) {
    return window.navigator.language
  }

  return saved
}

export function setPreferredLanguage(language: string) {
  localStorage.setItem(saveLanguageKey, language)
}
