import * as React from 'react'
import { PlasmicBtnPhoto, DefaultBtnPhotoProps } from './plasmic/driver_app/PlasmicBtnPhoto'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

interface BtnPhotoProps extends DefaultBtnPhotoProps {
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void
}

function BtnPhoto_(props: BtnPhotoProps, ref: HTMLElementRefOf<'button'>) {
  return <PlasmicBtnPhoto root={{ ref }} {...props} />
}

const BtnPhoto = React.forwardRef(BtnPhoto_)
export default BtnPhoto
