import React, { useRef, useEffect, useContext, useState, useCallback, MutableRefObject, useMemo } from 'react'
import UserContext from 'context/UserContext'
import { useModal } from 'react-modal-hook'
import DistributorSetupContext from 'context/DistributorSetupContext'

const breakpoints = ['screen and (max-width: 768px)', 'screen and (min-width: 769px)']

export function useMedia() {
  const media = breakpoints.map(breakpoint => window.matchMedia(breakpoint))

  const getMatches = () => media.map(mql => mql.matches)
  const [matches, setMatches] = useState(getMatches)

  useEffect(() => {
    const handler = () => {
      setMatches(getMatches)
    }
    media.forEach(mql => mql.addListener(handler))
    return () => media.forEach(mql => mql.removeListener(handler))
    // eslint-disable-next-line
  }, [])

  return matches
}

export function useUser() {
  const user = useContext(UserContext)
  return user
}

export function useDistributorSetup() {
  const distributorSetup = useContext(DistributorSetupContext)
  return distributorSetup
}

function useInterval(callback: () => any, delay: number) {
  const savedCallback = useRef<() => any>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback?.current) {
        savedCallback.current()
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

/**
 * Detect when we have hit bottom
 * @param {Callback} callback
 * @param {Number} offsetTrigger How many pixels above the bottom of the list we should be before we consider having hit (rock) bottom.
 * @param {Number} throttleAmount
 */

/**
 * Hook to assist us in implementing infinite scroll.
 *
 * @param {Function} onFetchMore Callback
 * @param {Number} offsetTrigger How many pixels above the bottom of the list we should be before we consider having hit (rock) bottom.
 * @param {Boolean} enabled Used to disable the callbacks when e.g. data is loading
 */

export function useDebounce<T>(value: T, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )

  return debouncedValue
}

export function useKeyDown(
  keys: string[],
  callback: (event: KeyboardEvent) => void,
  ref: React.MutableRefObject<HTMLElement | null> | HTMLDocument = document
) {
  const handleKeyDown = useCallback(
    (evt: Event) => {
      if (evt instanceof KeyboardEvent) {
        if (!keys.includes(evt.key)) return
        callback(evt)
      }
    },
    [keys, callback]
  )

  useEffect(() => {
    let elmt: HTMLElement | HTMLDocument
    if ('current' in ref && ref.current instanceof HTMLElement) elmt = ref.current
    else if (ref instanceof HTMLDocument) elmt = ref
    else return

    elmt.addEventListener('keydown', handleKeyDown)

    return () => {
      elmt.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown, ref])
}

export const useCallbackClick = (): [MutableRefObject<any>, (node: any) => void] => {
  const ref: MutableRefObject<any> = useRef(null)
  const setRef = useCallback((node: any) => {
    if (ref.current) {
      // Make sure to cleanup any events/references added to the last instance
    }

    if (node) {
      node.click?.()
    }

    ref.current = node
  }, [])

  return [ref, setRef]
}

export const useModalWithData = (modalFactory: (modalData: any) => React.FunctionComponent) => {
  const [modalData, setModalData] = useState(undefined)
  const modalComponent = useMemo(() => modalFactory(modalData), [modalData, modalFactory])
  const [_showModal, hideModal] = useModal(modalComponent, [modalData])

  const showModal = useCallback(
    (data?: any) => {
      setModalData(data)
      _showModal()
    },
    [_showModal]
  )

  return [showModal, hideModal]
}

export function useCurrentTime() {
  const update = () => {
    return new Date()
  }
  const [value, setValue] = useState(update)

  useInterval(() => {
    const newValue = update()
    if (value !== newValue) {
      setValue(newValue)
    }
  }, 60000)

  return value
}
