import * as React from 'react'
import { PlasmicBtnSettings, DefaultBtnSettingsProps } from './plasmic/driver_app/PlasmicBtnSettings'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import ClickAwayListener from 'react-click-away-listener'
import { removeLoginToken } from 'modules/authentication/util'
import client from 'apollo'
import { useNavigate } from 'react-router-dom'

interface BtnSettingsProps extends DefaultBtnSettingsProps {
  initialOpen?: boolean
}

function BtnSettings_(props: BtnSettingsProps, ref: HTMLElementRefOf<'button'>) {
  const { initialOpen, ...plasmicProps } = props
  const [open, setOpen] = React.useState(initialOpen ?? false)
  const navigate = useNavigate()
  const logout = () => {
    removeLoginToken()
    client.resetStore().then(() => navigate('/'))
  }

  return (
    <PlasmicBtnSettings
      root={{ ref }}
      open={open}
      onClick={() => setOpen(!open)}
      link={{
        props: {
          onClick: () => logout(),
        },
        render: (props: any, Component: any) => (
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Component {...props} />
          </ClickAwayListener>
        ),
      }}
      {...plasmicProps}
    />
  )
}

const BtnSettings = React.forwardRef(BtnSettings_)
export default BtnSettings
