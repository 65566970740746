import * as React from 'react'
import { PlasmicListRow, DefaultListRowProps } from './plasmic/driver_app/PlasmicListRow'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

interface ListRowProps extends DefaultListRowProps {
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void
}

function ListRow_(props: ListRowProps, ref: HTMLElementRefOf<'a'>) {
  return <PlasmicListRow root={{ ref }} {...props} />
}

const ListRow = React.forwardRef(ListRow_)
export default ListRow
