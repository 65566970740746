import React, { useEffect, useState } from 'react'
import DistributorContext from 'context/DistributorContext'
import CenteredErrorMessage from 'components/CenteredErrorMessage/CenteredErrorMessage'
import UserContext from 'context/UserContext'
import MainLayout from './MainLayout'
import YouAreNotADriver from './YouAreNotADriver'
import * as GQL from 'generated/graphql'
import * as Sentry from '@sentry/react'
import { PlasmicRootProvider } from '@plasmicapp/react-web'
import { setContext } from '@apollo/client/link/context'
import AppContext, { IAppContext } from 'context/AppContext'
import { useApolloClient } from '@apollo/client'
import { link } from 'apollo'
import { getDomain } from 'util/env'
import queryString from 'query-string'
import { getLoginToken, setLoginToken } from 'modules/authentication/util'
import { Link, useLocation } from 'react-router-dom'
import { GraphQLError } from 'graphql'
import Loader from 'components/Loader'
import BtnFill from 'components/BtnFill'
import AccountSuspended from './AccountSuspended'

interface CustomGraphQLError extends GraphQLError {
  code?: any
  codeName?: string
}

// The primary role of this component is to bootstrap
// all necessary information about the current user and distributor,
// and then add this to different contexts
export default function Bootstrap() {
  const location = useLocation()
  const qs = queryString.parse(location.search)
  const [appContext, setAppContext] = useState<IAppContext>({})

  if (qs.token) {
    setLoginToken(qs.token as string)
  }

  const setContextLink = setContext((request, previousContext) => {
    if (previousContext.noAppContext) {
      return previousContext
    }
    return {
      ...previousContext,
      headers: {
        ...previousContext.headers,
        ...(Object.keys(appContext) as Array<keyof IAppContext>)
          .filter(o => appContext[o])
          .map(c => ({ ['context-' + c]: appContext[c]?.id }))
          .reduce((obj, col) => Object.assign(col, obj), {}),
      },
    }
  })

  const client = useApolloClient()
  client.setLink(setContextLink.concat(link))

  const { data, error } = GQL.useMe()

  const me = (data?.me as GQL.UserNode) || null
  const currentDistributor = (data?.me?.primaryDistributor || data?.me?.distributors?.[0] || null) as GQL.DistributorNode | null

  useEffect(() => {
    setAppContext(context => ({
      ...context,
      distributor: currentDistributor as GQL.DistributorNode,
    }))
  }, [currentDistributor])

  if (error && error?.graphQLErrors.length > 0 && (error?.graphQLErrors.find(e => true) as CustomGraphQLError)?.codeName === GQL.GqlErrors.AccessDenied) {
    return (
      <CenteredErrorMessage className='none' message='No access to the system'>
        <div style={{ marginTop: '2em' }}>
          <Link to='/logout'>
            <BtnFill title='Log Out' />
          </Link>
        </div>
      </CenteredErrorMessage>
    )
  }

  // @ts-ignore
  if (error && error.networkError?.statusCode === 402) {
    return <AccountSuspended />
  }

  if (!getLoginToken()) {
    const domain = window.location.protocol + '//login.' + getDomain() + (getDomain().includes('localhost') ? ':3001' : window.location.port)
    window.location.href = domain
    return null
  }

  if (!data && !error) {
    return <Loader />
  }

  if (error && error?.graphQLErrors.length > 0 && (error?.graphQLErrors.find(e => true) as CustomGraphQLError)?.codeName !== 'PERMISSION_DENIED') {
    return <CenteredErrorMessage className='none' />
  }

  if (!me) {
    return (
      <CenteredErrorMessage className='none' message='No access to the system'>
        <div style={{ marginTop: '2em' }}>
          <Link to='/logout'>
            <BtnFill title='Log Out' />
          </Link>
        </div>
      </CenteredErrorMessage>
    )
  }

  if (me?.driver === null) {
    return <YouAreNotADriver />
  }

  Sentry.setUser({
    id: me?.id,
    username: me?.username,
    email: me?.email,
  })

  if (!appContext.driver) {
    setAppContext(context => ({
      ...context,
      driver: me.driver as GQL.DriverNode,
    }))
  }

  // Render the primary internal view, wrapped in the distributor context
  // and the user context
  return (
    <DistributorContext.Provider value={currentDistributor}>
      <AppContext.Provider value={{ appContext, setAppContext }}>
        <UserContext.Provider value={me}>
          {/* We can now safely create the routing context, as we have the distributor basename in place */}
          <PlasmicRootProvider>
            <MainLayout />
          </PlasmicRootProvider>
        </UserContext.Provider>
      </AppContext.Provider>
    </DistributorContext.Provider>
  )
}
