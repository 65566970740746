// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react'
import { classNames } from '@plasmicapp/react-web'

export type FillChecksvgIconProps = React.ComponentProps<'svg'> & {
  title?: string
}

export function FillChecksvgIcon(props: FillChecksvgIconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      version={'1.1'}
      x={'0'}
      y={'0'}
      viewBox={'0 0 48 48'}
      xmlSpace={'preserve'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M24 0C10.745 0 0 10.745 0 24s10.745 24 24 24 24-10.745 24-24S37.255 0 24 0zm8.598 19.865L22.264 30.199a.547.547 0 01-.774 0l-6.088-6.088a.547.547 0 010-.774l1.291-1.291a.547.547 0 01.774 0l4.41 4.411 8.656-8.656a.547.547 0 01.774 0l1.292 1.29a.548.548 0 01-.001.774z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  )
}

export default FillChecksvgIcon
/* prettier-ignore-end */
