// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react'
import { classNames } from '@plasmicapp/react-web'

export type FillCrosssvgIconProps = React.ComponentProps<'svg'> & {
  title?: string
}

export function FillCrosssvgIcon(props: FillCrosssvgIconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      version={'1.1'}
      x={'0'}
      y={'0'}
      viewBox={'0 0 48 48'}
      xmlSpace={'preserve'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M24 0C10.745 0 0 10.745 0 24s10.745 24 24 24 24-10.745 24-24S37.255 0 24 0zm8.094 30.352c.16.16.16.42 0 .581l-1.162 1.162c-.16.16-.42.16-.581 0l-1.086-1.086L24 25.743l-6.352 6.352c-.16.16-.42.16-.581 0l-1.162-1.162a.412.412 0 010-.581L22.258 24l-6.352-6.352a.412.412 0 010-.581l1.162-1.162c.16-.16.421-.16.581 0L24 22.258l6.352-6.352c.16-.16.421-.16.581 0l1.162 1.162c.16.16.16.42 0 .581l-1.086 1.086L25.743 24l6.351 6.352z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  )
}

export default FillCrosssvgIcon
/* prettier-ignore-end */
