// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import * as p from '@plasmicapp/react-web'
import { PlasmicVerifyExchangeProduct, DefaultVerifyExchangeProductProps } from './plasmic/driver_app/PlasmicVerifyExchangeProduct'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

import * as GQL from 'generated/graphql'
import { omit } from 'lodash'
import DiscrepancyActions from './DiscrepancyActions'
// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface VerifyExchangeProductProps extends Omit<DefaultVerifyExchangeProductProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultVerifyExchangeProductProps altogether and have
// total control over the props for your component.
export interface VerifyExchangeProductProps extends DefaultVerifyExchangeProductProps {
  discrepancyActions?: p.Flex<typeof DiscrepancyActions>
  product?: GQL.Maybe<GQL.ProductNode>
  quantity: number
  setQuantity: (quantity: number) => void
}

function VerifyExchangeProduct_(props: VerifyExchangeProductProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicVerifyExchangeProduct to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicVerifyExchangeProduct are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all VerifyExchangeProductProps here, but feel free
  // to do whatever works for you.
  return (
    <PlasmicVerifyExchangeProduct
      root={{ ref }}
      {...omit(props, 'product', 'quantity', 'setQuantity')}
      cardProductInline={{ product: props.product, quantity: props.quantity, setQuantity: props.setQuantity }}
    />
  )
}

const VerifyExchangeProduct = React.forwardRef(VerifyExchangeProduct_)
export default VerifyExchangeProduct
