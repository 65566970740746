import * as React from 'react'
import { PlasmicProductTable, DefaultProductTableProps } from './plasmic/driver_app/PlasmicProductTable'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

interface ProductTableProps extends DefaultProductTableProps {
  rows?: React.ReactNode
  addProductBtn?: any
}

function ProductTable_(props: ProductTableProps, ref: HTMLElementRefOf<'div'>) {
  return <PlasmicProductTable root={{ ref }} {...props} />
}

const ProductTable = React.forwardRef(ProductTable_)
export default ProductTable
