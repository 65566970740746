// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicNotes, DefaultNotesProps } from './plasmic/driver_app/PlasmicNotes'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface NotesProps extends Omit<DefaultNotesProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultNotesProps altogether and have
// total control over the props for your component.
export interface NotesProps extends DefaultNotesProps {
  notes?: any
}

function Notes_(props: NotesProps, ref: HTMLElementRefOf<'div'>) {
  return <PlasmicNotes root={{ ref }} notes={props.notes} />
}

const Notes = React.forwardRef(Notes_)
export default Notes
