import * as React from 'react'
import { PlasmicDropdownLink, DefaultDropdownLinkProps } from './plasmic/driver_app/PlasmicDropdownLink'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

interface DropdownLinkProps extends DefaultDropdownLinkProps {
  href: string
}

function DropdownLink_(props: DropdownLinkProps, ref: HTMLElementRefOf<'a'>) {
  return <PlasmicDropdownLink root={{ ref }} {...props} />
}

const DropdownLink = React.forwardRef(DropdownLink_)
export default DropdownLink
