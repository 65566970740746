import * as React from 'react'
import axios from 'axios'
import { getLoginToken } from 'modules/authentication/util'

interface NetworkImageProps {
  url?: string
  className?: string
}

const NetworkImage: React.FC<NetworkImageProps> = props => {
  // Components refs
  const img: React.Ref<HTMLImageElement> = React.createRef()
  const token = getLoginToken()
  const { url } = props

  // Load data
  React.useEffect(() => {
    if (url) {
      axios
        .get(url, {
          responseType: 'arraybuffer',
          headers: {
            authorization: token ? `Bearer ${token}` : '',
            'system-origin': 'DriverPortal',
          },
        })
        .then(res => {
          const blob = new Blob([res.data])

          const objectURL = URL.createObjectURL(blob)
          if (img.current != null) img.current!.src = objectURL
        })
    }
  }, [token, url, img])

  return <img src={''} alt={'Loading...'} ref={img} {...props} />
}

export default NetworkImage
