// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicBtnIcon, DefaultBtnIconProps } from './plasmic/driver_app/PlasmicBtnIcon'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

interface BtnIconProps extends DefaultBtnIconProps {
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void
}

function BtnIcon_(props: BtnIconProps, ref: HTMLElementRefOf<'button'>) {
  return <PlasmicBtnIcon root={{ ref }} {...props} />
}

const BtnIcon = React.forwardRef(BtnIcon_)
export default BtnIcon
