import React from 'react'
import * as GQL from 'generated/graphql'

export interface IAppContext {
  depot?: GQL.DepotNode | null
  distributor?: GQL.DistributorNode | null
  driver?: GQL.DriverNode | null
}

interface ISwitchContext {
  appContext: IAppContext
  setAppContext: (appContext: IAppContext | ((context: IAppContext) => IAppContext)) => void
}

const AppContext = React.createContext<ISwitchContext>({
  appContext: {},
  setAppContext: (appContext: IAppContext | ((context: IAppContext) => IAppContext)) => {},
})

export default AppContext
