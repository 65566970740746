// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react'
import { classNames } from '@plasmicapp/react-web'

export type ArrowLeftsvgIconProps = React.ComponentProps<'svg'> & {
  title?: string
}

export function ArrowLeftsvgIcon(props: ArrowLeftsvgIconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      version={'1.1'}
      x={'0'}
      y={'0'}
      viewBox={'0 0 37.015 24.821'}
      xmlSpace={'preserve'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M36.015 11.41H3.413l9.703-9.703A.999.999 0 1011.702.293L.292 11.703a1.001 1.001 0 000 1.414l11.41 11.41a.997.997 0 001.414 0 .999.999 0 000-1.414L3.413 13.41h32.603a1 1 0 10-.001-2z'
        }
      ></path>
    </svg>
  )
}

export default ArrowLeftsvgIcon
/* prettier-ignore-end */
