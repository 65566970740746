import * as React from 'react'
import { PlasmicRoute, DefaultRouteProps } from './plasmic/driver_app/PlasmicRoute'
import * as p from '@plasmicapp/react-web'
import RouteTimerBtn from './RouteTimerBtn'
import BtnStroke from './BtnStroke'
import RouteSummary from './RouteSummary'
import BtnFill from './BtnFill'
import { getPositionFromDate } from 'util/date-fns/custom'
import styled from 'styled-components'

const StyledPlasmicRoute = styled(PlasmicRoute)<{ offset: number }>`
  .timelineScroller {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .timelineScroller::-webkit-scrollbar {
    display: none;
  }
  .indicatorLine {
    left: ${props => props.offset}px;
  }
`

interface RouteProps extends DefaultRouteProps {
  content: p.Flex<'div'>
  indicator?: p.Flex<'div'>
  btnOpenPdf?: any
  timelineScroller?: p.Flex<'div'>
  timelineNumberWrapper?: p.Flex<'div'>
  routeTimerBtn?: p.Flex<typeof RouteTimerBtn>
  addStop?: p.Flex<typeof BtnStroke>
  routeSummary?: p.Flex<typeof RouteSummary>
  btnCompleteRoute?: p.Flex<typeof BtnFill>
  navigation?: any
}

function Route_(props: RouteProps, ref: p.HTMLElementRefOf<'div'>) {
  const timelineScrollRef = React.useRef(null)

  const setTimelineScrollRef = React.useCallback((node: any) => {
    if (node) {
      node.scrollLeft = getPositionFromDate(new Date()) - 100
    }
    timelineScrollRef.current = node
  }, [])

  return (
    <StyledPlasmicRoute
      root={{ ref }}
      offset={getPositionFromDate(new Date())}
      timelineScroller={{
        props: {
          ref: setTimelineScrollRef,
        },
      }}
      {...props}
    />
  )
}

const Route = React.forwardRef(Route_)
export default Route
