// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type RefreshsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function RefreshsvgIcon(props: RefreshsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 91.351 91.35"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M45.657.003C20.482.003 0 20.485 0 45.659 0 70.834 20.482 91.35 45.657 91.35S91.35 70.834 91.35 45.659c0-1.605-.098-3.193-.268-4.79a4.568 4.568 0 00-5.01-4.078h-.012a4.567 4.567 0 00-4.05 5.023c.135 1.278.205 2.56.205 3.845 0 20.237-16.32 36.557-36.558 36.557-20.237 0-36.549-16.32-36.549-36.557 0-20.237 16.312-36.548 36.55-36.548 8.442 0 16.53 2.908 22.971 8.109l-6.307 6.307a2.285 2.285 0 001.623 3.88h18.27a2.284 2.284 0 002.293-2.265V6.862A2.282 2.282 0 0083.09 4.74a2.278 2.278 0 00-.749-.173 2.281 2.281 0 00-1.749.679l-5.504 5.504A45.694 45.694 0 0045.657 0v.003z"
        }
      ></path>
    </svg>
  );
}

export default RefreshsvgIcon;
/* prettier-ignore-end */
