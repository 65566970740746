// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react'
import { classNames } from '@plasmicapp/react-web'

export type ScLogoSymbolsvgIconProps = React.ComponentProps<'svg'> & {
  title?: string
}

export function ScLogoSymbolsvgIcon(props: ScLogoSymbolsvgIconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      version={'1.1'}
      x={'0'}
      y={'0'}
      viewBox={'0 0 340 340'}
      xmlSpace={'preserve'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M157.6 222.3c0-17.7-4.6-35.2-13.3-50.6-.1-.3-.3-.5-.4-.8-.1-.2-.3-.5-.4-.7-9-15.2-21.8-28-37.1-36.8l-28.7-16.6-37.9-21.7c-2.6-1.5-4.6-.3-4.6 2.7v29.9c0 3 2.1 6.6 4.6 8.1 0 0 .1 0 2.5 1.5C65 151.8 84.1 171.4 98 194.6c.5.8.9 1.5 1.3 2.3.4.8.9 1.5 1.3 2.3 13.1 23.6 20.6 50 21.7 76.9.1 2.7.1 2.9.1 2.9.1 3 2.2 6.5 4.7 8l25.9 14.9c2.6 1.5 4.6.3 4.6-2.7v-76.9zm63.7-112.9c-15.3 8.8-32.8 13.6-50.4 13.8h-1.8c-17.7-.2-35.1-4.9-50.4-13.8L90 92.8 52.2 71c-2.6-1.5-2.6-3.9 0-5.4l25.9-14.9c2.6-1.5 6.7-1.5 9.3-.1 0 0 .1.1 2.5 1.4 23.9 12.4 50.5 19.2 77.4 19.6h5.4c27-.4 53.6-7.2 77.4-19.6 2.4-1.3 2.5-1.4 2.5-1.4 2.6-1.4 6.7-1.4 9.3.1l25.9 14.9c2.6 1.5 2.6 3.9 0 5.4L250 92.8l-28.7 16.6zm-38.9 112.9c0-17.7 4.6-35.2 13.3-50.6.1-.3.3-.5.4-.8.1-.2.3-.5.4-.7 9-15.2 21.8-28 37.1-36.8l28.7-16.6L300.1 95c2.6-1.5 4.6-.3 4.6 2.7v29.9c0 3-2.1 6.6-4.6 8.1 0 0-.1 0-2.5 1.5a174.34 174.34 0 00-55.7 57.3c-.5.8-.9 1.5-1.3 2.3-.4.8-.9 1.5-1.3 2.3-13.1 23.6-20.6 50-21.7 76.9-.1 2.7-.1 2.9-.1 2.9 0 3-2.2 6.5-4.7 8L187 301.8c-2.6 1.5-4.6.3-4.6-2.7v-76.8z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  )
}

export default ScLogoSymbolsvgIcon
/* prettier-ignore-end */
