import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import {
  getCustomerIsOpenNow,
  getCustomerOpeningWindowToday,
  getNextStopIdFromRoute,
  getRouteDeliveredBottles,
  getRouteTotalBottles,
  getRouteVisitedStops,
} from '../util'
import Loader from 'components/Loader'
import * as GQL from 'generated/graphql'
import PlasmicRoute from 'components/Route'
import CustomerInstance from 'components/CustomerInstance'
import { parseISO } from 'date-fns'
import TimelineNumber from 'components/TimelineNumber'
import AddStop from 'components/AddStop'
import { useModal } from 'react-modal-hook'
import DepotInstance from 'components/DepotInstance'
import { getPositionFromDate } from 'util/date-fns/custom'
import CacheConfigs from 'util/cacheConfig'
import { getUrl } from 'util/env'

interface RouteDetailProps {}

const StyledTimelineNumber = styled(TimelineNumber)<{ offset: number }>`
  left: ${props => props.offset}px;
  position: absolute;
`

const Wrapper = styled.div`
  height: 100vh;
  margin: 0 auto;
  padding-top: 50px;
`

const RouteDetail: React.FC<RouteDetailProps> = () => {
  const navigate = useNavigate()
  // const location = useLocation();

  const routeId = useParams().routeId
  if (!routeId) navigate('/routes')

  const { data, loading } = GQL.useRoute({
    ...CacheConfigs.ACCURATE_FREQUENT,
    notifyOnNetworkStatusChange: true,
    variables: {
      id: routeId!,
    },
  })

  const [completeRoute] = GQL.useCompleteRoute({
    refetchQueries: ['Routes'],
    onCompleted: () => {
      navigate('/routes')
    },
  })

  const [showAddStopModal, hideAddStopModal] = useModal(() => <AddStop routeId={routeId!} onRequestClose={hideAddStopModal} />)

  const route = data?.route as GQL.RouteNode
  const nextStop = getNextStopIdFromRoute(route) as string
  const [openStop, setOpenStop] = useState<string | null | undefined>()
  const [startRoute] = GQL.useStartRoute()
  const [geolocateRoute] = GQL.useGeolocateRoute()

  const showRoutePdf = () => {
    const pdfURL = `https://solace.${getUrl()}/route/pdf/${route?.id}/`
    window.open(pdfURL, '_blank')
  }

  const startGeolocation = useCallback(() => {
    if (route && route.status === GQL.RouteStatus.Started) {
      navigator.geolocation.watchPosition(
        position => {
          const coordinates = position.coords
          geolocateRoute({
            variables: {
              id: routeId!,
              latitude: coordinates.latitude,
              longitude: coordinates.longitude,
              altitude: coordinates.altitude,
              altitudeAccuracy: coordinates.altitudeAccuracy,
              accuracy: coordinates.accuracy,
              speed: coordinates.speed,
              heading: coordinates.heading,
            },
          })
        },
        error => {
          console.error(error)
        },
        {
          maximumAge: 10000,
          enableHighAccuracy: false,
        }
      )
    }
  }, [route, geolocateRoute, routeId])

  useEffect(() => {
    startGeolocation()
  }, [startGeolocation])

  useEffect(() => {
    setOpenStop(nextStop)
  }, [nextStop])

  return (
    <>
      {!route && loading ? (
        <PlasmicRoute
          navigation={{
            props: {
              buttonBack: false,
            },
          }}
          timelineNumberWrapper={<></>}
          routeTimerBtn={<></>}
          content={
            <Wrapper>
              <Loader />
            </Wrapper>
          }
        />
      ) : !route ? (
        <PlasmicRoute
          navigation={{
            props: {
              buttonBack: false,
            },
          }}
          timelineNumberWrapper={<></>}
          routeTimerBtn={<></>}
          content={<Wrapper>Couldn't find route</Wrapper>}
        />
      ) : (
        <PlasmicRoute
          navigation={{
            props: {
              buttonBack: true,
              btnBack: {
                onClick: () => navigate('/routes'),
              },
            },
          }}
          timelineNumberWrapper={{
            props: {
              children: route.stops?.map((stop, index) => (
                <StyledTimelineNumber
                  number={stop?.orderInRoute}
                  key={index}
                  cancelled={stop?.status === GQL.StopStatus.Cancelled}
                  delivered={stop?.status === GQL.StopStatus.Completed}
                  next={nextStop === stop?.id}
                  offset={stop ? getPositionFromDate(parseISO(stop.expectedArrivalTime)) : 0}
                />
              )),
            },
          }}
          content={{
            props: {
              children: route.stops?.map((stop, index) => (
                <React.Fragment key={index}>
                  {stop?.stopType === GQL.StopStopType.Pickup ? (
                    <DepotInstance
                      data-testid='depot-instance'
                      stop={stop as GQL.StopNode}
                      route={route}
                      closed={stop?.id !== openStop}
                      customer={'Reload at ' + route?.depot?.name}
                      onClick={() => setOpenStop(stop?.id as string)}
                    />
                  ) : (
                    <CustomerInstance
                      data-testid='customer-instance'
                      stop={stop as GQL.StopNode}
                      route={route}
                      openingHours={stop?.customer ? getCustomerOpeningWindowToday(stop.customer) : '-'}
                      openIndicator={{
                        props: {
                          status: stop?.customer ? getCustomerIsOpenNow(stop.customer) : undefined,
                        },
                      }}
                      customer={stop?.customer?.name}
                      closed={stop?.id !== openStop}
                      onClick={() => setOpenStop(stop?.id as string)}
                      onComplete={() => setOpenStop(getNextStopIdFromRoute(route) as string)}
                      verifyPickup={stop?.customer?.distributor?.inventoryAccess !== GQL.InventoryAccess.NoAccess}
                      noteIndicator={!!stop?.order?.notes && stop.order.notes.length > 0}
                    />
                  )}
                </React.Fragment>
              )),
            },
          }}
          routeTimerBtn={{
            props: {
              onClick: () => {
                startRoute({ variables: { id: route.id } })
                startGeolocation()
              },
              started: route.status === GQL.RouteStatus.Started,
              route: route,
            },
          }}
          btnOpenPdf={{ onClick: () => showRoutePdf() }}
          addStop={{
            props: {
              onClick: () => showAddStopModal(),
            },
          }}
          routeSummary={{
            props: {
              customersTotal: route.stops?.length,
              cylindersTotal: getRouteTotalBottles(route),
              customersConfirmed: getRouteVisitedStops(route),
              cylindersConfirmed: getRouteDeliveredBottles(route),
              route: route,
            },
          }}
          btnCompleteRoute={{
            props: {
              name: 'completeRoute',
              onClick: () =>
                completeRoute({
                  variables: {
                    id: routeId!,
                  },
                }),
            },
          }}
        />
      )}
    </>
  )
}

export default RouteDetail
