// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react'
import { classNames } from '@plasmicapp/react-web'

export type CheckCircsvgIconProps = React.ComponentProps<'svg'> & {
  title?: string
}

export function CheckCircsvgIcon(props: CheckCircsvgIconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      version={'1.1'}
      x={'0'}
      y={'0'}
      viewBox={'0 0 42.045 42.046'}
      xmlSpace={'preserve'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={'currentColor'}>
        <path
          d={
            'M21.022 42.046C9.431 42.046 0 32.615 0 21.022 0 9.431 9.431 0 21.022 0s21.022 9.431 21.022 21.022c.001 11.593-9.43 21.024-21.022 21.024zm0-39.046C11.085 3 3 11.085 3 21.022c0 9.938 8.085 18.023 18.022 18.023s18.022-8.085 18.022-18.023C39.045 11.085 30.96 3 21.022 3z'
          }
        ></path>

        <path
          d={
            'M17.677 28.809c-.384 0-.768-.146-1.061-.439l-5.879-5.879a1.5 1.5 0 112.121-2.121l4.818 4.818 11.511-11.511a1.5 1.5 0 112.121 2.121L18.737 28.369c-.293.293-.676.44-1.06.44z'
          }
        ></path>
      </g>
    </svg>
  )
}

export default CheckCircsvgIcon
/* prettier-ignore-end */
