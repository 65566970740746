import React from 'react'
import theme from '../theme'
import client from '../apollo'
import { ThemeProvider } from 'styled-components'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter as Router } from 'react-router-dom'
import Bootstrap from './Bootstrap'
import IntlContextProvider from './IntlContextProvider'

export default function Root() {
  return (
    <ApolloProvider client={client}>
      <IntlContextProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <Bootstrap />
          </Router>
        </ThemeProvider>
      </IntlContextProvider>
    </ApolloProvider>
  )
}
