import { Cookies } from 'react-cookie'
import { getRootDomain } from 'util/env'

const LOGIN_TOKEN_KEY = 'login-token'

export function setLoginToken(token: string) {
  return new Cookies().set(LOGIN_TOKEN_KEY, token as string, {
    path: '/',
    domain: '.' + getRootDomain(),
    maxAge: 60 * 60 * 24 * 30,
  })
}

export function getLoginToken(): string | null {
  return new Cookies().get(LOGIN_TOKEN_KEY)
}

export function removeLoginToken() {
  return new Cookies().remove(LOGIN_TOKEN_KEY, {
    path: '/',
    domain: '.' + getRootDomain(),
  })
}
