import * as React from 'react'
import {
  PlasmicSelect__Option as PlasmicSelectOption,
  DefaultSelect__OptionProps as DefaultSelectOptionProps,
} from './plasmic/driver_app/PlasmicSelect__Option'
import { SelectOptionRef } from '@plasmicapp/react-web'

interface SelectOptionProps extends DefaultSelectOptionProps {}

function SelectOption_(props: SelectOptionProps, ref: SelectOptionRef) {
  const { plasmicProps } = PlasmicSelectOption.useBehavior(props, ref)
  return <PlasmicSelectOption {...plasmicProps} />
}

const SelectOption = React.forwardRef(SelectOption_)

export default Object.assign(SelectOption, {
  __plumeType: 'select-option',
})
