import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { removeLoginToken } from 'modules/authentication/util'
import client from 'apollo'

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.primaryDarker};
  width: 100%;
  height: 100%;
  flex-direction: column;
`

const Title = styled.h3`
  color: white;
  max-width: 80%;
  text-align: center;
  opacity: 0.8;
`

const Logout = styled.span`
  color: white;
  opacity: 0.5;
  text-decoration: underline;
  cursor: pointer;
`

interface YouAreNotADriverProps {}

const YouAreNotADriver: React.FC<YouAreNotADriverProps> = () => {
  const intl = useIntl()
  const t = intl.formatMessage

  function logout() {
    removeLoginToken()
    client.resetStore()
  }
  return (
    <Layout>
      <Title>{t({ id: 'common.you-are-not-a-driver' })}</Title>
      <Logout onClick={logout}>{t({ id: 'common.logout' })}</Logout>
    </Layout>
  )
}

export default YouAreNotADriver
