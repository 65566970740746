import * as React from 'react'
import { PlasmicLetterGroup, DefaultLetterGroupProps } from './plasmic/driver_app/PlasmicLetterGroup'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import * as p from '@plasmicapp/react-web'
import ListLetterhead from './ListLetterhead'

interface LetterGroupProps extends DefaultLetterGroupProps {
  listLetterhead?: p.Flex<typeof ListLetterhead>
}

function LetterGroup_(props: LetterGroupProps, ref: HTMLElementRefOf<'div'>) {
  return <PlasmicLetterGroup root={{ ref }} {...props} />
}

const LetterGroup = React.forwardRef(LetterGroup_)
export default LetterGroup
