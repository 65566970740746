import { isNumber } from 'lodash'

const fullRegex = /^(\d{1,2}):?(\d{1,2}?:?)(\d{1,2})?\.?\d*/g
export const fullRegexStrict = /^\d{1,2}:\d{1,2}:\d{1,2}\.\d*/g
export const twoDigitRegex = /(\d{1,2})/g

export function wrap(number: number, max: number) {
  return (number + max) % max
}

/**
 * Checks heuristically whether or not we can deduce a time directly. This is the case in scenarios like
 *    1 → 01:00
 *    15 → 15:00
 *    2300 → 23:00
 *    15:00:01.34234 → 15:00:01.34234
 * @param {String} value
 * @return {bool} wether we can deduce time
 */
export function heuristicIsATimeString(value: string) {
  if (value.length <= 3 && value.match(twoDigitRegex)) return true
  return !!value.match(fullRegex)
}

export function normalizeTuple(tuple: (number | string)[]): number[] {
  const baseArray: number[] = new Array(4).fill(0)
  const mapped: number[] = baseArray.map((_, index) => {
    if (index >= tuple.length) {
      return 0
    }

    const entry = tuple[index]

    if (isNumber(entry)) {
      return entry
    }

    return parseInt(entry, 10)
  })

  return [(mapped[0] + 24) % 24, (mapped[1] + 60) % 60, (mapped[2] + 60) % 60, (mapped[3] + 1e9) % 1e9]
}
