import * as React from 'react'
import { PlasmicModalPhoto, DefaultModalPhotoProps } from './plasmic/driver_app/PlasmicModalPhoto'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import Modal from './Modal/Modal'
import styled from 'styled-components'
import BtnPhoto from './BtnPhoto'
import { use100vh } from 'react-div-100vh'
import { useCallbackClick } from 'util/hooks'
import { useIntl } from 'react-intl'

const ImageInput = styled.input`
  position: absolute;
  top: -9999px;
`

const ActiveImage = styled.img`
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: 50% center;
  max-height: 60%;
  min-width: 0;
  min-height: 0;
`

const PreviewImage = styled.img`
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
`

const StyledModalPhoto = styled(PlasmicModalPhoto)<{ height: string }>`
  height: ${props => props.height};
`

export interface ImageData {
  url: string
  blob: File
  taken: Date
}

interface ModalPhotoProps extends DefaultModalPhotoProps {
  onRequestClose: () => void
  onDoneCallback?: (images: ImageData[]) => void
  initialImageData: ImageData[]
}

interface CaptureData {
  target: {
    files: FileList | null
    validity: ValidityState
  }
}

function ModalPhoto_(props: ModalPhotoProps, ref: HTMLElementRefOf<'div'>) {
  const { onRequestClose, onDoneCallback, initialImageData, ...plasmicProps } = props

  const intl = useIntl()
  const t = intl.formatMessage
  const [inputFileRef, setInputFileRef] = useCallbackClick()
  const [images, setImages] = React.useState(initialImageData)
  const [activeImage, setActiveImage] = React.useState<ImageData | null>(initialImageData[0])

  const handleCapture = ({ target: { files, validity } }: CaptureData) => {
    if (validity.valid && files && files.length > 0) {
      const imageData = {
        blob: files[0],
        url: URL.createObjectURL(files[0]),
        taken: new Date(),
      }
      setActiveImage(imageData)
      setImages([imageData, ...images])
    }
  }

  const handleDone = (images: ImageData[]) => {
    onRequestClose()
    if (onDoneCallback) {
      onDoneCallback(images)
    }
  }

  const handleDelete = () => {
    const areYouSure = window.confirm(t({ id: 'common.are-you-sure' }))

    if (areYouSure && activeImage) {
      const newImageList = images.filter(c => c !== activeImage)
      setImages(newImageList)
      setActiveImage(newImageList[0])
    }
  }

  const height = use100vh()

  return (
    <Modal isOpen onRequestClose={onRequestClose}>
      <ImageInput type='file' accept='image/*' capture='environment' ref={setInputFileRef} onChange={e => handleCapture(e)} />
      <StyledModalPhoto
        height={height ? height + 'px' : '100vh'}
        root={{ ref }}
        date={activeImage?.taken.toLocaleString() ?? <div></div>}
        btnCloseModal={{
          props: {
            onClick: () => onRequestClose(),
          },
        }}
        btnOpenCamera={{
          props: {
            onClick: () => inputFileRef.current.click(),
          },
        }}
        btnDone={{
          props: {
            onClick: () => handleDone(images),
          },
        }}
        btnDelete={{
          props: {
            onClick: () => handleDelete(),
          },
        }}
        activePhoto={activeImage ? <ActiveImage src={activeImage.url} /> : ''}
        photos={images.map((i, n) => (
          <BtnPhoto key={n} onClick={() => setActiveImage(i)} image={<PreviewImage src={i.url} />} />
        ))}
        {...plasmicProps}
      />
    </Modal>
  )
}

const ModalPhoto = React.forwardRef(ModalPhoto_)
export default ModalPhoto
