// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import client from '../apollo'

import * as GQL from 'generated/graphql'
import { DefaultNavigationProps, PlasmicNavigation } from './plasmic/driver_app/PlasmicNavigation'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import { removeLoginToken } from '../modules/authentication/util'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface NavigationProps extends Omit<DefaultNavigationProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultNavigationProps altogether and have
// total control over the props for your component.
export interface NavigationProps extends DefaultNavigationProps {}

function Navigation_(props: NavigationProps, ref: HTMLElementRefOf<'div'>) {
  const [isOpened, setIsOpened] = useState(false)
  const [isStandalone, setIsStandalone] = useState(false)

  const navigate = useNavigate()

  const [logout] = GQL.useLogout()
  const [logoutOnAllDevices] = GQL.useLogoutOnAllDevices()

  const handleLogout = () => {
    logout({
      onCompleted: () => {
        removeLoginToken()
        client.resetStore().finally(() => navigate('/'))
      },
    })
  }

  const handleLogoutOnAllDevices = () => {
    logoutOnAllDevices({
      onCompleted: () => {
        removeLoginToken()
        client.resetStore().finally(() => navigate('/'))
      },
    })
  }

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsStandalone(true)
    }
  }, [])

  // Use PlasmicNavigation to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicNavigation are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all NavigationProps here, but feel free
  // to do whatever works for you.

  return (
    <PlasmicNavigation
      root={{ ref }}
      {...props}
      buttonRefresh={isStandalone}
      btnRefresh={{
        onClick: () => window.location.reload(),
      }}
      menuOpen={isOpened}
      menu={{
        props: {
          onClick: () => setIsOpened(prev => !prev),
        },
      }}
      btnLogout={{
        props: {
          onClick: () => handleLogout(),
        },
      }}
      btnLogoutOnAllDevices={{
        props: {
          onClick: () => handleLogoutOnAllDevices(),
        },
      }}
    />
  )
}

const Navigation = React.forwardRef(Navigation_)
export default Navigation
