/**
 * This function returns props.theme.colors[props.color] if it exists, otherwise
 * props.color.
 *
 * @param {Object} props The props passed to a component.
 * @param {String} index
 * @return {String} the color
 */
export function overloadColorProp(props, index = 'color') {
  return props[index] in props.theme.colors ? props.theme.colors[props[index]] : props[index]
}
