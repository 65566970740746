import * as React from 'react'
import {
  PlasmicSelect__OptionGroup as PlasmicSelectOptionGroup,
  DefaultSelect__OptionGroupProps as DefaultSelectOptionGroupProps,
} from './plasmic/driver_app/PlasmicSelect__OptionGroup'

interface SelectOptionGroupProps extends DefaultSelectOptionGroupProps {}

function SelectOptionGroup(props: SelectOptionGroupProps) {
  const { plasmicProps } = PlasmicSelectOptionGroup.useBehavior(props)
  return <PlasmicSelectOptionGroup {...plasmicProps} />
}

export default Object.assign(SelectOptionGroup, {
  __plumeType: 'select-option-group',
})
