// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react'
import { classNames } from '@plasmicapp/react-web'

export type CheckmarksvgIconProps = React.ComponentProps<'svg'> & {
  title?: string
}

export function CheckmarksvgIcon(props: CheckmarksvgIconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      version={'1.1'}
      x={'0'}
      y={'0'}
      viewBox={'0 0 21 15.248'}
      xmlSpace={'preserve'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M18.331.193L7.954 10.569 2.668 5.281a.655.655 0 00-.928 0L.193 6.828a.655.655 0 000 .928l7.298 7.298a.655.655 0 00.928 0L20.807 2.667a.655.655 0 000-.928L19.258.193a.654.654 0 00-.927 0z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  )
}

export default CheckmarksvgIcon
/* prettier-ignore-end */
