// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react'
import { classNames } from '@plasmicapp/react-web'

export type CamerasvgIconProps = React.ComponentProps<'svg'> & {
  title?: string
}

export function CamerasvgIcon(props: CamerasvgIconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      version={'1.1'}
      x={'0'}
      y={'0'}
      viewBox={'0 0 50.087 42.853'}
      xmlSpace={'preserve'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={'currentColor'}>
        <circle cx={'25.044'} cy={'23.374'} r={'8.348'}></circle>

        <path
          d={
            'M43.409 7.792h-2.89c-1.47 0-3.078-1.131-3.576-2.514l-.993-2.764C35.452 1.132 33.844 0 32.375 0H17.712c-1.469 0-3.078 1.131-3.575 2.514l-.993 2.763c-.498 1.382-2.106 2.514-3.575 2.514H6.678C3.005 7.792 0 10.796 0 14.47v21.704c0 3.673 3.005 6.678 6.678 6.678h36.73c3.673 0 6.678-3.005 6.678-6.678V14.47c.001-3.674-3.004-6.678-6.677-6.678zM25.044 35.896c-6.915 0-12.522-5.605-12.522-12.522s5.606-12.522 12.522-12.522 12.522 5.605 12.522 12.522-5.606 12.522-12.522 12.522zM44.522 16.14a1.39 1.39 0 01-1.391 1.391H41.74a1.39 1.39 0 01-1.391-1.391v-1.391a1.39 1.39 0 011.391-1.391h1.391a1.39 1.39 0 011.391 1.391v1.391z'
          }
        ></path>
      </g>
    </svg>
  )
}

export default CamerasvgIcon
/* prettier-ignore-end */
