import * as React from 'react'
import { PlasmicBtnStroke, DefaultBtnStrokeProps } from './plasmic/driver_app/PlasmicBtnStroke'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

interface BtnStrokeProps extends DefaultBtnStrokeProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

function BtnStroke_(props: BtnStrokeProps, ref: HTMLElementRefOf<'button'>) {
  return <PlasmicBtnStroke root={{ ref }} {...props} />
}

const BtnStroke = React.forwardRef(BtnStroke_)
export default BtnStroke
