// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react'
import { classNames } from '@plasmicapp/react-web'

export type LocationsvgIconProps = React.ComponentProps<'svg'> & {
  title?: string
}

export function LocationsvgIcon(props: LocationsvgIconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      version={'1.1'}
      x={'0'}
      y={'0'}
      viewBox={'0 0 42.203 42.202'}
      xmlSpace={'preserve'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M39.454.132c-.312.124-.623.247-.94.371-1.573.623-3.145 1.251-4.718 1.874L26.521 5.27l-8.353 3.323c-2.651 1.053-5.306 2.112-7.957 3.165-2.033.806-4.065 1.617-6.093 2.423-.905.361-1.815.717-2.72 1.083-.687.277-1.241.781-1.37 1.548-.178 1.068.45 2.122 1.528 2.369.519.119 1.043.218 1.563.326 2.596.544 5.188 1.088 7.784 1.627 2.433.509 4.866 1.019 7.304 1.528.272.054.539.114.811.168.054.01.114.02.168.035.03.005.059.015.084.02h.002l.003.002c.01.012.019.022.027.028l.004.007c.084.452.192.905.285 1.357l1.587 7.576c.524 2.512 1.053 5.024 1.578 7.537.077.36.151.72.227 1.08.004.058.012.117.025.176.302 1.286 1.815 1.948 2.972 1.301.485-.272.757-.692.954-1.192.129-.317.252-.638.381-.954.633-1.587 1.261-3.175 1.894-4.762l2.903-7.294c1.108-2.784 2.216-5.573 3.323-8.358l3.16-7.942c.801-2.018 1.607-4.035 2.408-6.053.356-.895.722-1.785 1.068-2.685.613-1.578-1.064-3.186-2.617-2.577z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  )
}

export default LocationsvgIcon
/* prettier-ignore-end */
