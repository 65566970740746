/* eslint-disable react/jsx-pascal-case */
import * as React from 'react'
import { PlasmicCustomerInstance, DefaultCustomerInstanceProps } from './plasmic/driver_app/PlasmicCustomerInstance'
import { useModalWithData } from 'util/hooks'
import ModalPhoto, { ImageData } from './ModalPhoto'
import BtnPhoto from './BtnPhoto'
import styled from 'styled-components'
import * as GQL from 'generated/graphql'
import { useIntl } from 'react-intl'
import * as p from '@plasmicapp/react-web'
import ProductTable from './ProductTable'
import OpenIndicator from './OpenIndicator'
import ProductTableRow from './ProductTableRow'
import NetworkImage from './NetworkImage'
import DropdownLink from './DropdownLink'
import ClickAwayListener from 'react-click-away-listener'
import Select__Option from './Select__Option'
import Notes from './Notes'
import Note from './Note'
import { parseISO } from 'date-fns'

const PreviewImage = styled.img`
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
`

const PreviewNetworkImage = styled(NetworkImage)`
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
`

interface Product {
  product: string
  quantity: string
  exchangeCylinderFee: boolean
  service: boolean
}

interface CustomerInstanceProps extends DefaultCustomerInstanceProps {
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void
  productTable?: p.Flex<typeof ProductTable>
  openIndicator?: p.Flex<typeof OpenIndicator>
  route: GQL.RouteNode
  stop: GQL.StopNode
  closed: boolean
  onComplete?: () => void
}

function CustomerInstance_(props: CustomerInstanceProps, ref: p.HTMLElementRefOf<'div'>) {
  const { stop, route, closed, onComplete, ...plasmicProps } = props
  const [hasVerifiedExchange, setHasVerifiedExchange] = React.useState(!plasmicProps.verifyPickup ? true : stop.hasVerifiedExchange || false)
  const intl = useIntl()
  const t = intl.formatMessage
  const [openComment, openCommentOnClick] = React.useState(false)
  const [images, setImages] = React.useState<ImageData[]>([])
  const [orderedCylinders, setOrderedCylinders] = React.useState(stop.order?.numberOfCylinders + '')
  const [comment, setComment] = React.useState<string | undefined>(stop.driversComment ?? undefined)
  const [products, setProducts] = React.useState<Product[]>([])
  const [actionsOpen, setActionsOpen] = React.useState(false)

  const [showImageModal, hideImageModal] = useModalWithData(
    (imageData: ImageData[]) =>
      function Modal() {
        return <ModalPhoto onRequestClose={hideImageModal} onDoneCallback={setImages} initialImageData={imageData} />
      }
  )

  const { data: notesData } = GQL.useAllCylinderGroupNotes({
    variables: {
      cylinderGroup: stop.order?.cylinderGroup.id!,
      translationKey: 'notes.type.general',
    },
  })

  const deliveryNotes = React.useMemo(() => {
    return notesData?.allCylinderGroupNotes?.map(note => note as GQL.CylinderGroupNoteNode) || []
  }, [notesData])

  const { data: dataNoteType } = GQL.useNoteType({
    variables: {
      key: 'notes.type.driver',
    },
  })
  const noteType = React.useMemo(() => dataNoteType?.cylinderGroupNoteTypeByKey?.id || null, [dataNoteType])

  const { data: productsData } = GQL.useAllProducts()
  const availableProducts = React.useMemo(() => productsData?.availableProducts?.edges.map(edge => edge?.node as GQL.ProductNode) || [], [productsData])

  const [addNote] = GQL.useAddCylinderNote()

  const [exchangeStock, { loading: exchangeStockLoading }] = GQL.useExchangeStock({
    onCompleted: data => {
      if (data.exchangeStock?.ok) setHasVerifiedExchange(true)
    },
  })

  const [completeStop] = GQL.useCompleteStop({
    onCompleted(response) {
      if (!response.completeStop?.ok || !comment || comment?.trim() === '') return
      const cylinderGroup = stop.order?.cylinderGroup
      addNote({
        variables: {
          cylinderGroup: cylinderGroup?.id!,
          note: comment,
          noteType: noteType!,
          severity: GQL.CylinderGroupNoteSeverity.High,
        },
      })
    },
    update: (cache, { data }) => {
      cache.writeFragment({
        id: cache.identify({
          id: data?.completeStop?.stop?.id,
          __typename: 'StopNode',
        }),
        fragment: GQL.StopInfo,
        fragmentName: 'StopInfo',
        data: data?.completeStop?.stop,
      })

      if (data?.completeStop?.nextStop?.id) {
        cache.writeFragment({
          id: cache.identify({
            id: data?.completeStop?.nextStop?.id,
            __typename: 'StopNode',
          }),
          fragment: GQL.StopInfo,
          fragmentName: 'StopInfo',
          data: data?.completeStop?.nextStop,
        })
      }
    },
  })

  const [cancelStop] = GQL.useCancelStop({
    update: (cache, { data }) => {
      cache.writeFragment({
        id: cache.identify({
          id: data?.cancelStop?.stop?.id,
          __typename: 'StopNode',
        }),
        fragment: GQL.StopInfo,
        fragmentName: 'StopInfo',
        data: data?.cancelStop?.stop,
      })
    },
  })

  const handleVerifyExchange = (exchangeInfos: GQL.InputExchangeStock[]) => {
    if (exchangeStockLoading) return
    exchangeStock({
      variables: {
        exchanges: exchangeInfos,
      },
    })
  }

  const handleComplete = () => {
    completeStop({
      variables: {
        id: stop.id,
        images: images.map<File>(i => {
          return i.blob
        }),
        comment: comment,
        products: products.map(item => ({ product: item.product, quantity: parseInt(item.quantity), exchangeCylindersFee: item.exchangeCylinderFee })),
        stockCount: stockCount,
      },
      // onCompleted() {},
    })

    if (onComplete) {
      onComplete()
    }
  }

  const handleCancel = () => {
    const areYouSure = window.confirm(t({ id: 'common.are-you-sure' }))

    if (areYouSure) {
      cancelStop({
        variables: { id: stop.id },
      })

      if (onComplete) {
        onComplete()
      }
    }
  }

  const stopCompleted = [GQL.StopStatus.Cancelled, GQL.StopStatus.Completed].includes(stop.status)

  const stock = stop.order?.cylinderGroup.stock

  const [stockCount] = React.useState(stock?.currentFull || 0)

  const addProduct = React.useCallback((productId?: string, quantity?: number, service: boolean = false) => {
    setProducts(prev => {
      const newItem = {
        product: productId || '',
        quantity: quantity?.toString() || '1',
        exchangeCylinderFee: !service,
        service: service,
      } as Product
      if (prev.at(-1)?.product === '' || prev.at(-1)?.quantity === '') return prev
      return [...prev, newItem]
    })
  }, [])

  // The purpose of this is to make https://smartcylinders.atlassian.net/browse/SW-345 possible
  // Once all active orders in the system are adapted to using products, this can be removed.
  React.useEffect(() => {
    const order = stop.order
    const p = availableProducts.find(product => product.weight === order?.gasWeight && product.type === order?.gasType?.toUpperCase())
    if ((order?.products && order.products.length > 0) || !p) return
    addProduct(p.id, order?.numberOfCylinders, !p.isGasType)
  }, [addProduct, stop.order, availableProducts])

  const handleProduct = (event: string | null, index: number, property: 'product' | 'quantity') => {
    if (!event) return
    setProducts(prev => {
      const orderProductsCopy = [...prev]
      orderProductsCopy[index][property] = event
      return orderProductsCopy.filter(product => parseInt(product.quantity) > 0)
    })
  }

  React.useEffect(() => {
    if (products.length > 0) return
    stop?.order?.products?.map(p => addProduct(p?.product?.id, p?.quantity, !p?.product?.isGasType))
  }, [stop, addProduct, products])

  return (
    <PlasmicCustomerInstance
      root={{ ref }}
      cancelled={stop.status === GQL.StopStatus.Cancelled}
      delivered={stop.status === GQL.StopStatus.Completed}
      address={stop.customer?.address?.firstLine}
      notes={
        <Notes
          notes={
            <>
              {deliveryNotes.map(e => (
                <Note
                  key={e?.id}
                  text={e.note}
                  by={'by: ' + (e?.createdBy ? e?.createdBy?.firstName + ' ' + e?.createdBy?.lastName : e?.author ? e?.author?.name : 'Customer')}
                  date={parseISO(e?.createdAt).toLocaleDateString() + ' ' + parseISO(e?.createdAt).toLocaleTimeString()}
                />
              ))}
            </>
          }
        />
      }
      openComment={{
        props: {
          onChange: (e: any) => setComment(e?.target.value),
          onClick: () => openCommentOnClick(!openComment),
          value: comment,
          disabled: stopCompleted,
        },
      }}
      comment={openComment}
      photos={
        stopCompleted
          ? stop.images?.map((i, n) => <BtnPhoto key={n} image={<PreviewNetworkImage url={i?.url as string} />} />)
          : images.map((i, n) => <BtnPhoto onClick={() => showImageModal(images)} key={n} image={<PreviewImage src={i?.url as string} />} />)
      }
      openCamera={{
        props: {
          onClick: () => showImageModal(images),
        },
      }}
      closed={closed || stop.status === GQL.StopStatus.Cancelled}
      depot={stop.stopType === GQL.StopStopType.Pickup}
      photoSection={stopCompleted ? (stop?.images?.length || 0) > 0 : images.length > 0}
      btnCancel={{
        props: {
          onClick: () => handleCancel(),
          disable: stopCompleted,
        },
      }}
      btnConfirm={{
        props: {
          name: 'confirmDelivery',
          onClick: () => handleComplete(),
          disable: stopCompleted || !hasVerifiedExchange,
        },
      }}
      openMaps={{
        props: {
          onClick: () =>
            (window.location.href =
              'https://www.google.com/maps/dir/?api=1&destination=' + stop.customer?.address.latitude + ',' + stop.customer?.address?.longitude),
        },
      }}
      productTable={{
        props: {
          addProductBtn: {
            onClick: () => addProduct(),
          },
          rows:
            products && products.length > 0 ? (
              // This section is only added for backwards compatibility with current orders.
              // It can likely be removed once all active orders have products.
              products
                .filter(product => !product.service)
                .map((product, index) => (
                  <ProductTableRow
                    key={`${product.quantity}-${product?.product}`}
                    loadCheckbox={stop.stopType === GQL.StopStopType.Pickup}
                    productSelect={{
                      props: {
                        value: product.product,
                        onChange: (event: string | null) => handleProduct(event, index, 'product'),
                        children: availableProducts
                          .filter(product => product.isGasType)
                          .map(product => (
                            <Select__Option aria-label='product' value={product?.id}>
                              {product?.displayName}
                            </Select__Option>
                          )),
                      },
                    }}
                    quantitySelect={{
                      props: {
                        value: product.quantity,
                        onChange: (event: string | null) => handleProduct(event, index, 'quantity'),
                        children: [...new Array(13)].map((_, i) => (
                          <Select__Option aria-label='product-quantity' value={i.toString()}>
                            {i.toString()}
                          </Select__Option>
                        )),
                      },
                    }}
                  />
                ))
            ) : (
              <ProductTableRow
                loadCheckbox={stop.stopType === GQL.StopStopType.Pickup}
                productSelect={{
                  props: {
                    value: '1',
                    children: (
                      <Select__Option aria-label='product' value='1'>
                        {stop.order?.gasWeight}kg {stop.order?.gasType}
                      </Select__Option>
                    ),
                  },
                }}
                quantitySelect={{
                  props: {
                    value: orderedCylinders,
                    onChange: (value: string | null) => setOrderedCylinders(value ?? orderedCylinders),
                    children: (
                      <Select__Option aria-label='product-quantity' value={orderedCylinders}>
                        {orderedCylinders}
                      </Select__Option>
                    ),
                  },
                }}
              />
            ),
        },
      }}
      verifyExchange={
        stop.customer?.distributor.inventoryAccess === GQL.InventoryAccess.BasicAccess
          ? {
              orders: [stop.order],
              onVerifyExchange: handleVerifyExchange,
              fromInventory: { vehicleId: route.vehicle?.id || '' },
              toInventory: { cylinderGroupId: stop.order?.cylinderGroup?.id || '' },
              stop: stop,
              route: route,
              reason: GQL.InventoryAdjustmentReason.Return,
              fromState: GQL.InventoryStockState.NotEmpty,
              toState: GQL.InventoryStockState.Empty,
              disabled: hasVerifiedExchange || stopCompleted,
              isLoading: exchangeStockLoading,
              closed: false,
              name: 'verifyExchange',
            }
          : null
      }
      btnMoreActions={{
        props: {
          onClick: () => setActionsOpen(!actionsOpen),
          open: actionsOpen,
          listActions: {
            props: {
              children: <DropdownLink href={'tel:' + stop.customer?.phoneNumber}>Call customer</DropdownLink>,
            },
            render: (props: any, Component: any) => (
              <ClickAwayListener onClickAway={() => setActionsOpen(false)}>
                <Component {...props} />
              </ClickAwayListener>
            ),
          },
        },
      }}
      zip={''}
      {...plasmicProps}
    />
  )
}

const CustomerInstance = React.forwardRef(CustomerInstance_)
export default CustomerInstance
