import * as React from 'react'
import {
  PlasmicSelect__Overlay as PlasmicSelectOverlay,
  DefaultSelect__OverlayProps as DefaultSelectOverlayProps,
} from './plasmic/driver_app/PlasmicSelect__Overlay'
import { TriggeredOverlayRef } from '@plasmicapp/react-web'

interface SelectOverlayProps extends DefaultSelectOverlayProps {}

function SelectOverlay_(props: SelectOverlayProps, ref: TriggeredOverlayRef) {
  const { plasmicProps } = PlasmicSelectOverlay.useBehavior(props, ref)
  return <PlasmicSelectOverlay {...plasmicProps} />
}

const SelectOverlay = React.forwardRef(SelectOverlay_)

export default Object.assign(SelectOverlay, {
  __plumeType: 'triggered-overlay',
})
