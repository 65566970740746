import * as React from 'react'
import { PlasmicListRowRoute, DefaultListRowRouteProps } from './plasmic/driver_app/PlasmicListRowRoute'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

interface ListRowRouteProps extends DefaultListRowRouteProps {
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void
}

function ListRowRoute_(props: ListRowRouteProps, ref: HTMLElementRefOf<'a'>) {
  return <PlasmicListRowRoute root={{ ref }} {...props} />
}

const ListRowRoute = React.forwardRef(ListRowRoute_)
export default ListRowRoute
