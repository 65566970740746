import * as React from 'react'
import { PlasmicOverview, DefaultOverviewProps } from './plasmic/driver_app/PlasmicOverview'
import * as p from '@plasmicapp/react-web'
import RoutesList from './RoutesList'
import BtnSettings from './BtnSettings'

interface OverviewProps extends DefaultOverviewProps {
  routesList?: p.Flex<typeof RoutesList>
  btnSettings?: p.Flex<typeof BtnSettings>
}

function Overview_(props: OverviewProps, ref: p.HTMLElementRefOf<'div'>) {
  return <PlasmicOverview root={{ ref }} {...props} />
}

const Overview = React.forwardRef(Overview_)
export default Overview
