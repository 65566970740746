// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicAddStop, DefaultAddStopProps } from './plasmic/driver_app/PlasmicAddStop'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import Modal from './Modal/Modal'
import * as GQL from 'generated/graphql'
import LetterGroup from './LetterGroup'
import ListRow from './ListRow'
import { useDebounce } from 'util/hooks'
import styled from 'styled-components'
import Loader from './Loader'
import { use100vh } from 'react-div-100vh'

interface AddStopProps extends DefaultAddStopProps {
  onRequestClose: () => void
  onAddCallback?: (customer: string, route: string) => void
  routeId: string
}

interface AlphabeticArray<T> {
  [key: string]: T[]
}

const StyledPlasmicAddStop = styled(PlasmicAddStop)<{ height: string }>`
  width: 100vw;
  height: ${props => props.height};
`

function AddStop_(props: AddStopProps, ref: HTMLElementRefOf<'div'>) {
  const { onRequestClose, onAddCallback, routeId, ...plasmicProps } = props

  const [search, setSearch] = React.useState('')

  const debouncedSearch = useDebounce(search, 500)

  const { data } = GQL.useAllCustomers({
    variables: {
      first: 1000,
      search: debouncedSearch,
    },
  })

  const [addStop] = GQL.useCreateStop({
    refetchQueries: ['Route'],
  })

  const customersByLetter = data?.allCustomers?.edges.reduce((accumulated, value) => {
    if (value?.node) {
      const capital = value?.node?.name.charAt(0).toUpperCase() || ''
      if (!accumulated.hasOwnProperty(capital)) {
        accumulated[capital] = []
      }
      accumulated[capital] = [value.node as GQL.CustomerNode, ...accumulated[capital]]
    }
    return accumulated
  }, {} as AlphabeticArray<GQL.CustomerNode>)

  const height = use100vh()

  return (
    <Modal isOpen onRequestClose={onRequestClose}>
      <StyledPlasmicAddStop
        root={{ ref }}
        height={height ? height + 'px' : '100vh'}
        searchField={{
          props: {
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value),
          },
        }}
        btnCloseCross={{
          props: {
            onClick: onRequestClose,
          },
        }}
        list={
          customersByLetter ? (
            Object.keys(customersByLetter)
              .sort()
              .map((letter, i) => (
                <LetterGroup
                  listLetterhead={{
                    props: {
                      letter: letter,
                    },
                  }}
                  key={i}
                >
                  {customersByLetter[letter].map((customer, n) => (
                    <ListRow
                      key={n}
                      title={customer.name}
                      onClick={() => {
                        onRequestClose()
                        addStop({
                          variables: {
                            customer: customer.id,
                            route: routeId,
                          },
                        })
                        if (onAddCallback) onAddCallback(customer.id, routeId)
                      }}
                    />
                  ))}
                </LetterGroup>
              ))
          ) : (
            <Loader />
          )
        }
        {...plasmicProps}
      />
    </Modal>
  )
}

const AddStop = React.forwardRef(AddStop_)
export default AddStop
