import { Duration } from 'date-fns'
import { padStart } from 'lodash'

export function formatDuration(duration: Duration) {
  let str = `${padStart((duration.hours ?? 0).toString(), 2, '0')}:${padStart((duration.minutes ?? 0).toString(), 2, '0')}`

  if (duration.days) {
    str = `${duration.days}d ${str}`
  }

  if (duration.years) {
    str = `${duration.days}y ${str}`
  }

  return str
}
