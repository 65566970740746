import * as React from 'react'
import { PlasmicBtnFill, DefaultBtnFillProps } from './plasmic/driver_app/PlasmicBtnFill'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

interface BtnFillProps extends DefaultBtnFillProps {
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void
  disable?: boolean
}

function BtnFill_(props: BtnFillProps, ref: HTMLElementRefOf<'button'>) {
  const { disable, onClick, ...plasmicProps } = props

  const onClickIfEnabled = () => {
    if (disable || !onClick) {
      return
    }

    return onClick()
  }

  return <PlasmicBtnFill root={{ ref }} disable={disable} onClick={onClickIfEnabled} {...plasmicProps} />
}

const BtnFill = React.forwardRef(BtnFill_)
export default BtnFill
