import * as React from 'react'
import { PlasmicBtnActions, DefaultBtnActionsProps } from './plasmic/driver_app/PlasmicBtnActions'
import * as p from '@plasmicapp/react-web'
import ActionsDropdown from './ActionsDropdown'

interface BtnActionsProps extends DefaultBtnActionsProps {
  listActions?: p.Flex<typeof ActionsDropdown>
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void
  open?: boolean
}

function BtnActions_(props: BtnActionsProps, ref: p.HTMLElementRefOf<'button'>) {
  return <PlasmicBtnActions root={{ ref }} {...props} />
}

const BtnActions = React.forwardRef(BtnActions_)
export default BtnActions
