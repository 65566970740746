import * as React from 'react'
import { PlasmicCustomerInstance, DefaultCustomerInstanceProps } from './plasmic/driver_app/PlasmicCustomerInstance'
import * as GQL from 'generated/graphql'
import * as p from '@plasmicapp/react-web'
import ProductTable from './ProductTable'
import ProductTableRow from './ProductTableRow'
import SelectOption from './Select__Option'
import { Map as createMap } from 'immutable'
import { toLower, capitalize } from 'lodash'

interface CustomerInstanceProps extends DefaultCustomerInstanceProps {
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void
  productTable?: p.Flex<typeof ProductTable>
  stop: GQL.StopNode
  route: GQL.RouteNode
  closed: boolean
  onComplete?: () => void
}

function DepotInstance_(props: CustomerInstanceProps, ref: p.HTMLElementRefOf<'div'>) {
  const { stop, closed, route, onComplete, ...plasmicProps } = props
  const [hasVerifiedExchange] = React.useState(!plasmicProps.verifyPickup ? true : stop.hasVerifiedExchange || false)

  const [completeStop] = GQL.useCompleteStop({
    update: (cache, { data }) => {
      cache.writeFragment({
        id: cache.identify({
          id: data?.completeStop?.stop?.id,
          __typename: 'StopNode',
        }),
        fragment: GQL.StopInfo,
        fragmentName: 'StopInfo',
        data: data?.completeStop?.stop,
      })

      cache.writeFragment({
        id: cache.identify({
          id: data?.completeStop?.nextStop?.id,
          __typename: 'StopNode',
        }),
        fragment: GQL.StopInfo,
        fragmentName: 'StopInfo',
        data: data?.completeStop?.nextStop,
      })
    },
  })

  let foundNextRefill = false
  const orders = route.stops
    ?.slice(stop.orderInRoute + 1)
    .filter(s => {
      if (foundNextRefill) {
        return false
      }

      if (s?.stopType === GQL.StopStopType.Pickup) {
        foundNextRefill = true
        return false
      }

      return true
    })
    .map(s => s?.order)

  let ordersMap = createMap<string, number>()

  orders?.forEach(o => {
    if (!o?.products || o.products.length < 1) {
      if (o?.gasType?.toLowerCase() === GQL.ProductType.Other.toLowerCase()) return
      const key = o?.gasWeight?.toString() + ' kg ' + capitalize(toLower(o?.gasType?.toString()))
      ordersMap = ordersMap.set(key, ordersMap.get(key, 0) + o?.numberOfCylinders!)
      return
    }
    o?.products?.forEach(p => {
      if (!p?.product?.isGasType) return
      const key = p?.product?.displayName || p?.product?.weight?.toString() + 'kg ' + capitalize(toLower(p?.product?.type?.toString()))
      ordersMap = ordersMap.set(key, ordersMap.get(key, 0) + (p?.quantity || 0))
    })
  })

  const [loadedProducts, setLoadedProducts] = React.useState<string[]>([])

  const handleLoadchange = (add: boolean, key: string) => {
    if (add) {
      setLoadedProducts([...loadedProducts, key])
    } else {
      setLoadedProducts(loadedProducts.filter(k => k !== key))
    }
  }

  const hasLoadedProducts = ordersMap
    .keySeq()
    .toArray()
    .every(k => loadedProducts.includes(k))

  const handleComplete = () => {
    if (!hasLoadedProducts) {
      alert('Please verify that you loaded all products before completing pickup')
      return
    }

    completeStop({
      variables: {
        id: stop.id,
        images: [],
      },
    })

    if (onComplete) {
      onComplete()
    }
  }

  const pickupCompleted = [GQL.StopStatus.Cancelled, GQL.StopStatus.Completed].includes(stop.status)

  return (
    <PlasmicCustomerInstance
      root={{ ref }}
      cancelled={stop.status === GQL.StopStatus.Cancelled}
      delivered={stop.status === GQL.StopStatus.Completed}
      address={route.depot?.address?.firstLine}
      closed={closed || stop.status === GQL.StopStatus.Cancelled}
      depot
      btnConfirm={{
        props: {
          name: 'confirmPickup',
          onClick: () => handleComplete(),
          disable: !hasLoadedProducts || pickupCompleted || !hasVerifiedExchange,
        },
      }}
      openMaps={{
        props: {
          onClick: () =>
            (window.location.href =
              'https://www.google.com/maps/dir/?api=1&destination=' + route.depot?.address?.latitude + ',' + route.depot?.address?.longitude),
        },
      }}
      openIndicator={<></>}
      openingHours={<></>}
      productTable={{
        props: {
          addProductBtn: {
            render: () => <></>,
          },
          rows: ordersMap
            ?.mapEntries(([k, v], i) => [
              k,
              <ProductTableRow
                loadCheckbox
                loadedCheckbox={{
                  props: {
                    isDisabled: pickupCompleted,
                    name: 'productLoaded',
                    onChange: (isChecked: boolean) => handleLoadchange(isChecked, k),
                    ...(pickupCompleted ? { isChecked: true } : {}),
                  },
                }}
                key={i}
                productSelect={{
                  props: {
                    value: '1',
                    children: <SelectOption value='1'>{k}</SelectOption>,
                  },
                }}
                quantitySelect={{
                  props: {
                    value: v + '',
                    children: <SelectOption value={v + ''}>{v}</SelectOption>,
                  },
                }}
              />,
            ])
            .toSetSeq(),
        },
      }}
      {...plasmicProps}
    />
  )
}

const DepotInstance = React.forwardRef(DepotInstance_)
export default DepotInstance
