// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react'
import { classNames } from '@plasmicapp/react-web'

export type Icon2IconProps = React.ComponentProps<'svg'> & {
  title?: string
}

export function Icon2Icon(props: Icon2IconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 64 64'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M15.586 38.414a2 2 0 002.828 0L32 24.828l13.586 13.586a2 2 0 102.828-2.828L32 19.172 15.586 35.586a2 2 0 000 2.828z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  )
}

export default Icon2Icon
/* prettier-ignore-end */
