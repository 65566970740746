// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react'
import { classNames } from '@plasmicapp/react-web'

export type CrossClosesvgIconProps = React.ComponentProps<'svg'> & {
  title?: string
}

export function CrossClosesvgIcon(props: CrossClosesvgIconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      version={'1.1'}
      x={'0'}
      y={'0'}
      viewBox={'0 0 30.357 30.357'}
      xmlSpace={'preserve'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M17.3 15.178L29.918 2.561A1.5 1.5 0 1027.797.44L15.179 13.057 2.561.439A1.5 1.5 0 10.44 2.56l12.618 12.618L.439 27.796a1.5 1.5 0 102.122 2.121L15.179 17.3l12.618 12.618c.293.293.677.439 1.061.439s.768-.146 1.061-.439a1.5 1.5 0 000-2.121L17.3 15.178z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  )
}

export default CrossClosesvgIcon
/* prettier-ignore-end */
