// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react'
import { classNames } from '@plasmicapp/react-web'

export type ScDriversvgIconProps = React.ComponentProps<'svg'> & {
  title?: string
}

export function ScDriversvgIcon(props: ScDriversvgIconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      version={'1.1'}
      x={'0'}
      y={'0'}
      viewBox={'0 0 48 30'}
      xmlSpace={'preserve'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M3.721.47h26.67c1.877 0 3.416 1.556 3.416 3.434V7.05l6.966.017c.345-.003.67.16.875.438l5.755 7.976c.097.107.171.233.219.37l.017.067c.049.146.066.3.05.454v6.832a2.823 2.823 0 01-2.81 2.81h-.437c-.571 2.023-2.43 3.517-4.627 3.517-2.198 0-4.073-1.494-4.644-3.517h-18.83a4.845 4.845 0 01-4.644 3.517c-2.2 0-4.057-1.495-4.627-3.517H3.721c-1.877 0-3.416-1.555-3.416-3.433V3.905C.305 2.025 1.844.47 3.721.47zm12.957 8.683a1.078 1.078 0 001.127 1.026h10.572a1.076 1.076 0 10-.005-2.154H17.805a1.078 1.078 0 00-1.127 1.128zm17.954 1.952v5.25c0 .595.482 1.077 1.077 1.077h7.404a1.077 1.077 0 00.892-1.699l-3.736-5.25a1.076 1.076 0 00-.875-.454h-3.685a.85.85 0 00-.101 0 1.074 1.074 0 00-.976 1.076zm-21.758 1.75a1.078 1.078 0 10.102 2.154h15.401a1.076 1.076 0 10-.005-2.154H12.976a.868.868 0 00-.102 0c.001 0 .001 0 0 0zm-1.177 9.187A2.637 2.637 0 009.038 24.7v.017a2.648 2.648 0 002.659 2.659 2.662 2.662 0 002.675-2.608v-.067c0-1.49-1.193-2.66-2.675-2.66zM37.139 24.7a2.67 2.67 0 002.675 2.675 2.656 2.656 0 002.659-2.675 2.638 2.638 0 00-2.659-2.66 2.651 2.651 0 00-2.675 2.66z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  )
}

export default ScDriversvgIcon
/* prettier-ignore-end */
