// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicCardProductInline, DefaultCardProductInlineProps } from './plasmic/end_user_app_product_cards/PlasmicCardProductInline'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

import * as GQL from 'generated/graphql'
import { omit } from 'lodash'
import CountPicker from './CountPicker'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface CardProductInlineProps extends Omit<DefaultCardProductInlineProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultCardProductInlineProps altogether and have
// total control over the props for your component.
export interface CardProductInlineProps extends DefaultCardProductInlineProps {
  product?: GQL.Maybe<GQL.ProductNode>
  quantity: number
  setQuantity: (quantity: number) => void
}

function CardProductInline_(props: CardProductInlineProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicCardProductInline to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicCardProductInline are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all CardProductInlineProps here, but feel free
  // to do whatever works for you.

  return (
    <PlasmicCardProductInline
      cardProduct={{ ref }}
      {...omit(props, 'product', 'quantity', 'setQuantity')}
      infoProductTitle={props.product?.displayName}
      noPhoto={!props.product?.image?.image}
      imgProduct={props.product?.image?.image}
      countPicker={<CountPicker quantity={props.quantity} setQuantity={props.setQuantity} />}
    />
  )
}

const CardProductInline = React.forwardRef(CardProductInline_)
export default CardProductInline
