import { ApolloClient, InMemoryCache, ApolloLink, concat, from } from '@apollo/client'
import { getLoginToken } from 'modules/authentication/util'
import { API_URL, isLocal } from './util/env'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/react'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import CacheConfigs from 'util/cacheConfig'

const errorLink = onError(({ graphQLErrors, networkError, response, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      if (!isLocal()) {
        Sentry.captureException(new Error('[' + (path || []).join('/') + '] ' + message))
      }

      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`, locations)

      return null
    })
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
    Sentry.captureException(new Error('Network error' + networkError))
  }

  return forward(operation)
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getLoginToken()

  operation.setContext({
    headers: {
      ...operation.getContext().headers,
      authorization: token ? `Bearer ${token}` : ``,
      'system-origin': 'DriverPortal',
    },
  })

  return forward(operation)
})

const logMiddleware = new ApolloLink((operation, forward) => {
  if (!isLocal()) {
    Sentry.addBreadcrumb({
      category: 'gql',
      message: '[ QUERY ] ' + operation.operationName + ' (' + JSON.stringify(operation.variables) + ')',
      level: 'info' as Sentry.SeverityLevel,
    })
  }

  return forward(operation)
})

const uploadLink = createUploadLink({
  uri: API_URL + '/graphql',
  fetchOptions: {
    credentials: 'same-origin',
  },
})

export const link = from([errorLink, concat(logMiddleware, concat(authMiddleware, uploadLink as any))])

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: CacheConfigs.DEFAULT,
    query: CacheConfigs.DEFAULT,
  },
})

export default client
