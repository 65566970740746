// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicVerifyExchange, DefaultVerifyExchangeProps } from './plasmic/driver_app/PlasmicVerifyExchange'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

import * as GQL from 'generated/graphql'
import { omit } from 'lodash'
import VerifyExchangeProduct from './VerifyExchangeProduct'
import Loader from './Loader'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface VerifyExchangeProps extends Omit<DefaultVerifyExchangeProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultVerifyExchangeProps altogether and have
// total control over the props for your component.
export interface VerifyExchangeProps extends DefaultVerifyExchangeProps {
  orders?: (GQL.Maybe<GQL.CylinderGroupOrderNode> | undefined)[]
  fromInventory: InventoryLocation
  toInventory: InventoryLocation
  fromState: GQL.InventoryStockState
  toState: GQL.InventoryStockState
  received?: number
  returned?: number
  disabled: boolean
  reason?: GQL.InventoryAdjustmentReason
  route: GQL.RouteNode
  stop: GQL.StopNode
  sendToDepot?: boolean
  onVerifyExchange?: (exchanges: GQL.InputExchangeStock[]) => void
  isLoading?: boolean
  transformState?: boolean
  discrepancies?: boolean
  name?: string
}

interface InventoryLocation {
  cylinderGroupId?: string
  depotId?: string
  vehicleId?: string
}

function VerifyExchange_(props: VerifyExchangeProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicVerifyExchange to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicVerifyExchange are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all VerifyExchangeProps here, but feel free
  // to do whatever works for you.
  const [closed, setClosed] = React.useState(false)

  const orderProducts = React.useMemo(() => {
    return props.orders?.map(order => order?.products).flat() || []
  }, [props.orders])

  const gasProducts = React.useMemo(() => {
    return orderProducts?.filter(orderProduct => !!orderProduct?.product?.isGasType) || []
  }, [orderProducts])

  const [quantities, setQuantities] = React.useState<{ [key: string]: number }>({})
  const [discrepancyActions, setDiscrepancyAction] = React.useState<{ [key: string]: GQL.DiscrepancyAction | undefined }>({})

  const getQuantity = React.useCallback(
    (orderProduct: GQL.CylinderGroupOrderProductNode) => {
      if (!orderProduct) return 0
      return quantities[orderProduct.id]
    },
    [quantities]
  )
  const setQuantity = (orderProduct: GQL.CylinderGroupOrderProductNode, quantity: number) => {
    if (!orderProduct) return
    setQuantities({ ...quantities, [orderProduct.id]: quantity })
  }

  const setDiscrepancy = (orderProduct: GQL.CylinderGroupOrderProductNode, action: GQL.DiscrepancyAction | undefined) => {
    if (!orderProduct) return
    setDiscrepancyAction({ ...discrepancyActions, [orderProduct.id]: action })
  }

  const getExpectedAmountToPickup = (orderProduct: GQL.CylinderGroupOrderProductNode) => {
    return (orderProduct.quantity || 0) - (orderProduct?.exchangeCylindersFee ? orderProduct.quantity || 0 : 0)
  }

  const resolveDiscrepancy = (orderProduct: GQL.CylinderGroupOrderProductNode | null | undefined) => {
    if (!orderProduct || !props.discrepancies || getQuantity(orderProduct) === getExpectedAmountToPickup(orderProduct)) return undefined
    if (!!discrepancyActions[orderProduct.id]) return discrepancyActions[orderProduct.id]
    if (getQuantity(orderProduct) < getExpectedAmountToPickup(orderProduct)) return GQL.DiscrepancyAction.PostInvoiceDepositFee
    if (getQuantity(orderProduct) > getExpectedAmountToPickup(orderProduct)) return GQL.DiscrepancyAction.PostInvoiceDepositReturn
  }

  const getExchangeInput = (orderProduct?: GQL.Maybe<GQL.CylinderGroupOrderProductNode>) => {
    return {
      fromInventory: { ...props.fromInventory, productId: orderProduct?.product?.id || '' },
      toInventory: { ...props.toInventory, productId: orderProduct?.product?.id || '' },
      received: props.received !== undefined ? props.received : quantities[orderProduct?.id || ''] || 0,
      returned: props.returned !== undefined ? props.returned : orderProduct?.quantity || 0,
      fromState: props.fromState,
      toState: props.toState,
      reason: props.reason || GQL.InventoryAdjustmentReason.Other,
      stopId: props.stop.id,
      discrepancyAction: resolveDiscrepancy(orderProduct),
      orderProductId: orderProduct?.id || '',
      transformState: props.transformState,
    } as GQL.InputExchangeStock
  }

  const onVerifyExchangeClick = () => {
    if (orderProducts.length === 0) return
    if (props.onVerifyExchange) {
      if (props.sendToDepot) {
        return props.onVerifyExchange(
          orderProducts
            .filter(orderProduct => orderProduct?.product?.isGasType)
            .map(orderProduct => [
              getExchangeInput(orderProduct),
              // Send received products to the depot
              {
                fromInventory: { ...props.fromInventory, productId: orderProduct?.product?.id || '' },
                toInventory: { depotId: props.route.depot?.id || '', productId: orderProduct?.product?.id || '' },
                received: 0,
                returned: quantities[orderProduct?.id || ''] || 0,
                fromState: props.toState,
                toState: props.toState,
                reason: props.reason || GQL.InventoryAdjustmentReason.Return,
                orderProductId: orderProduct?.id || '',
                stopId: props.stop.id,
              } as GQL.InputExchangeStock,
            ])
            .flat()
        )
      }
      return props.onVerifyExchange(orderProducts.map(getExchangeInput))
    }
  }

  React.useEffect(() => {
    if (Object.keys(quantities).length > 0) return
    const _quantities: { [key: string]: number } = {}
    gasProducts.forEach(orderProduct => {
      if (!orderProduct) return
      _quantities[orderProduct.id] = getExpectedAmountToPickup(orderProduct)
    })
    setQuantities(_quantities)
    // eslint-disable-next-line
  }, [setQuantities, gasProducts, props.discrepancies])

  return (
    <PlasmicVerifyExchange
      root={{ ref }}
      {...omit(
        props,
        'onVerifyExchange',
        'onVerifyTransfer',
        'orders',
        'fromInventory',
        'toInventory',
        'received',
        'returned',
        'disabled',
        'stop',
        'route',
        'reason',
        'fromState',
        'toState',
        'sendToDepot',
        'transformState',
        'isLoading',
        'discrepancies',
        'name'
      )}
      closed={closed}
      openVerifyCylindersBtn={{ onClick: () => setClosed(prev => !prev) }}
      products={orderProducts
        .filter(orderProduct => orderProduct?.product?.isGasType)
        .map(orderProduct =>
          orderProduct ? (
            <VerifyExchangeProduct
              key={orderProduct.id}
              product={orderProduct.product}
              setQuantity={quantity => setQuantity(orderProduct, quantity)}
              quantity={getQuantity(orderProduct)}
              discrepancy={
                props.discrepancies && !discrepancyActions[orderProduct?.id || ''] && getQuantity(orderProduct) !== getExpectedAmountToPickup(orderProduct)
              }
              discrepancyActions={{
                btnPostInvoice: {
                  onClick: () => {
                    if (getQuantity(orderProduct) < getExpectedAmountToPickup(orderProduct)) {
                      return setDiscrepancy(orderProduct, GQL.DiscrepancyAction.PostInvoiceDepositFee)
                    }
                    setDiscrepancy(orderProduct, GQL.DiscrepancyAction.PostInvoiceDepositReturn)
                  },
                  title: getQuantity(orderProduct) < getExpectedAmountToPickup(orderProduct) ? 'Post-invoice deposit fee' : 'Post-invoice deposit return',
                },
                btnDismiss: { onClick: () => setDiscrepancy(orderProduct, GQL.DiscrepancyAction.Dismiss) },
              }}
            />
          ) : null
        )}
      btnFill={{
        onClick: () => onVerifyExchangeClick(),
        name: props.name,
        title: props.isLoading ? <Loader size={16} color='white' /> : 'Verify empty cylinders picked up',
        disable: props.disabled,
      }}
    />
  )
}

const VerifyExchange = React.forwardRef(VerifyExchange_)
export default VerifyExchange
