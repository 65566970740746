import isFunction from 'lodash/isFunction'
import { now } from '.'

// The following is a simple lookup where a number of regex-value-tuples exist for
// common keywords
const lookups = [
  [/(midnight|midnatt|night)/, [0, 0, 0, 0]],
  [/(noon|middag)/, [12, 0, 0, 0]],
  [
    /(now|nå)/u,
    () => {
      return now().toArray()
    },
  ],
  [
    /klokken[^\d]*(\d+)/,
    (match: RegExpMatchArray) => {
      return [parseInt(match[1]), 0, 0, 0]
    },
  ],
  [
    /clock[^\d]*(\d+)/,
    (match: RegExpMatchArray) => {
      return [parseInt(match[1]), 0, 0, 0]
    },
  ],
  [
    /(\d+).*clock/,
    (match: RegExpMatchArray) => {
      return [parseInt(match[1]), 0, 0, 0]
    },
  ],
  [
    /(\d+) timer siden/,
    (match: RegExpMatchArray) => {
      return now().sub(parseInt(match[1]), 'hours').toArray()
    },
  ],
  [
    /(\d+) hours ago/,
    (match: RegExpMatchArray) => {
      return now().sub(parseInt(match[1]), 'hours').toArray()
    },
  ],
  [
    /(\d+) hours/,
    (match: RegExpMatchArray) => {
      return now().add(parseInt(match[1]), 'hours').toArray()
    },
  ],
  [
    /(\d+) timer/,
    (match: RegExpMatchArray) => {
      return now().add(parseInt(match[1]), 'hours').toArray()
    },
  ],
  [
    /(\d+) min(?:utter)? siden/,
    (match: RegExpMatchArray) => {
      return now().sub(parseInt(match[1]), 'minutes').toArray()
    },
  ],
  [
    /(\d+) min(?:utes)? ago/,
    (match: RegExpMatchArray) => {
      return now().sub(parseInt(match[1]), 'minutes').toArray()
    },
  ],
  [
    /(\d+) minutes/,
    (match: RegExpMatchArray) => {
      return now().add(parseInt(match[1]), 'minutes').toArray()
    },
  ],
  [
    /(\d+) min(?:utter)?/,
    (match: RegExpMatchArray) => {
      return now().add(parseInt(match[1]), 'minutes').toArray()
    },
  ],
  [
    /(\d+) sek(?:under)? siden/,
    (match: RegExpMatchArray) => {
      return now().sub(parseInt(match[1]), 'seconds').toArray()
    },
  ],
  [
    /(\d+) sec(?:onds)? ago/,
    (match: RegExpMatchArray) => {
      return now().sub(parseInt(match[1]), 'seconds').toArray()
    },
  ],
  [
    /(\d+) seconds/,
    (match: RegExpMatchArray) => {
      return now().add(parseInt(match[1]), 'seconds').toArray()
    },
  ],
  [
    /(\d+) sekunder/,
    (match: RegExpMatchArray) => {
      return now().add(parseInt(match[1]), 'seconds').toArray()
    },
  ],
]

export function parseText(value: string): number[] | null {
  // Go through lookups. Accept the first matching
  for (let i = 0; i < lookups.length; ++i) {
    const regex = lookups[i][0] as RegExp
    const match = value.match(regex)
    if (match) {
      const lookupValue = lookups[i][1]
      if (isFunction(lookupValue)) return lookupValue(match)
      return lookupValue as number[]
    }
  }
  return null
}
