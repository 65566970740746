// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react'
import { classNames } from '@plasmicapp/react-web'

export type CommentCircsvgIconProps = React.ComponentProps<'svg'> & {
  title?: string
}

export function CommentCircsvgIcon(props: CommentCircsvgIconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      version={'1.1'}
      x={'0'}
      y={'0'}
      viewBox={'0 0 44.775 41.627'}
      xmlSpace={'preserve'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={'currentColor'}>
        <path
          d={
            'M14.435 14.418c-.813 0-1.426-.612-1.426-1.426s.613-1.426 1.426-1.426h17.653c.813 0 1.426.612 1.426 1.426s-.613 1.426-1.426 1.426H14.435zm0 6.695c-.813 0-1.426-.612-1.426-1.426s.613-1.426 1.426-1.426h17.653c.813 0 1.426.612 1.426 1.426s-.613 1.426-1.426 1.426H14.435zm0 6.697c-.813 0-1.426-.612-1.426-1.426s.613-1.426 1.426-1.426h17.653c.813 0 1.426.612 1.426 1.426s-.613 1.426-1.426 1.426H14.435z'
          }
        ></path>

        <path
          d={
            'M2.747 41.627a2.677 2.677 0 01-1.908-.796c-.849-.796-1.07-1.95-.588-3.022l2.627-6.19-.067-.156c-.715-1.671-1.063-3.331-1.063-5.078V14.331C1.747 6.429 8.176 0 16.078 0h14.366c7.902 0 14.331 6.429 14.331 14.331v10.714c0 7.901-6.401 14.33-14.27 14.33H14.739c-1.646 0-3.173-.276-4.539-.823l-.162-.065-6.062 2.876c-.414.153-.799.264-1.229.264zM16.078 2.852C9.749 2.852 4.6 8.001 4.6 14.331v12.053c0 1.366.307 2.771.887 4.06.321.751.32 1.549-.007 2.366l-2.545 5.962 5.89-2.836c.347-.16.732-.242 1.141-.242.367 0 .74.066 1.107.198 1.266.466 2.466.693 3.665.693h15.766c6.329 0 11.479-5.149 11.479-11.479V14.331c0-6.33-5.149-11.479-11.479-11.479H16.078z'
          }
        ></path>
      </g>
    </svg>
  )
}

export default CommentCircsvgIcon
/* prettier-ignore-end */
