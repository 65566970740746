// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicActionsDropdown, DefaultActionsDropdownProps } from './plasmic/driver_app/PlasmicActionsDropdown'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

interface ActionsDropdownProps extends DefaultActionsDropdownProps {}

function ActionsDropdown_(props: ActionsDropdownProps, ref: HTMLElementRefOf<'div'>) {
  return <PlasmicActionsDropdown root={{ ref }} {...props} />
}

const ActionsDropdown = React.forwardRef(ActionsDropdown_)
export default ActionsDropdown
