import React, { useRef } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import styled from 'styled-components'
import ContentScrollProvider from './ContentScrollProvider'
import RoutesOverview from 'modules/routing/RoutesOverview'
import RouteDetail from 'modules/routing/RouteDetail/RouteDetail'
import { ModalProvider } from 'react-modal-hook'
import Logout from 'modules/authentication/Logout'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  ${props => props.theme.layout.small} {
    display: flex;
    flex-direction: column;
  }
`

const ContentWrapper = styled.div`
  background-color: ${props => props.theme.colors.primaryDark};
  overflow: auto;
  height: 100%;
`

function MainLayout() {
  const contentRef = useRef(null)
  return (
    <Wrapper>
      <ContentWrapper ref={contentRef}>
        <ContentScrollProvider contentRef={contentRef}>
          <ModalProvider>
            <Routes>
              <Route path='/' element={<Navigate to='/routes' />} />
              <Route path='/logout' element={<Logout />} />
              <Route path='/routes' element={<RoutesOverview />} />
              <Route path='/routes/:routeId' element={<RouteDetail />} />
              <Route path='*' key='*' element={<Navigate to='/' />} />
            </Routes>
          </ModalProvider>
        </ContentScrollProvider>
      </ContentWrapper>
      {/* Modals and other containers */}
    </Wrapper>
  )
}

export default MainLayout
