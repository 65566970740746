import * as React from 'react'
import { PlasmicProductTableRow, DefaultProductTableRowProps } from './plasmic/driver_app/PlasmicProductTableRow'
import * as p from '@plasmicapp/react-web'
import Select from './Select'
import Checkbox from './Checkbox'

interface ProductTableRowProps extends DefaultProductTableRowProps {
  productSelect?: p.Flex<typeof Select>
  quantitySelect?: p.Flex<typeof Select>
  loadedCheckbox?: p.Flex<typeof Checkbox>
}

function ProductTableRow_(props: ProductTableRowProps, ref: p.HTMLElementRefOf<'div'>) {
  return <PlasmicProductTableRow root={{ ref }} {...props} />
}

const ProductTableRow = React.forwardRef(ProductTableRow_)
export default ProductTableRow
