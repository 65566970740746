import * as React from 'react'
import { PlasmicRouteTimerBtn, DefaultRouteTimerBtnProps } from './plasmic/driver_app/PlasmicRouteTimerBtn'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import { useCurrentTime } from 'util/hooks'
import * as GQL from 'generated/graphql'
import { formatDuration } from 'modules/routing/RouteDetail/RouteTimeline.util'
import { intervalToDuration } from 'date-fns'
import { parseISO } from 'date-fns'

interface RouteTimerBtnProps extends DefaultRouteTimerBtnProps {
  route?: GQL.RouteNode
}

function RouteTimerBtn_(props: RouteTimerBtnProps, ref: HTMLElementRefOf<'div'>) {
  const { route, ...plasmicProps } = props
  const now = useCurrentTime()

  return (
    <PlasmicRouteTimerBtn
      root={{ ref }}
      routeTimer={
        route && route.driverStartedRouteAt
          ? formatDuration(
              intervalToDuration({
                start: parseISO(route.driverStartedRouteAt!),
                end: now,
              })
            )
          : ''
      }
      {...plasmicProps}
    />
  )
}

const RouteTimerBtn = React.forwardRef(RouteTimerBtn_)
export default RouteTimerBtn
